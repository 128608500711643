import React, { useState, useEffect, useContext } from "react";
import { Grid, makeStyles, Paper, Box, ButtonBase } from "@material-ui/core";
import "./MyAccount.css";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import avatarIcon from "../../Assets/ProfilePage/avatar.png";
import badgeIcon from "../../Assets/ProfilePage/Badge.png";
import guardianIcon from "../../Assets/ProfilePage/Guardian.png";
import Container from "@material-ui/core/Container";
import { EditOutlined } from "@material-ui/icons";
import Button from "../../Components/Button/Button";
import { useHistory } from "react-router-dom";
import { api_call_token, login_api, api_call } from "../../Utils/Network";
import { IconButton, Skeleton } from "@mui/material";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Alert, Autocomplete } from "@material-ui/lab";

const errorIcon = () => {
    return (
        <>
            <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.5 5L5 9.5M5 5L9.5 9.5"
                    stroke="#FF0000"
                    stroke-opacity="0.8"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M7.25 13.5C10.7018 13.5 13.5 10.7018 13.5 7.25C13.5 3.79822 10.7018 1 7.25 1C3.79822 1 1 3.79822 1 7.25C1 10.7018 3.79822 13.5 7.25 13.5Z"
                    stroke="#FF0000"
                    stroke-opacity="0.8"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    );
};
function NewMyAccount() {
    let nameTest = /^[a-zA-Z ]{2,30}$/;
    let emailTest = "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$";
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const { user, setUser, boardList, gradeList, countrylist, statelist } = useContext(UserCredsContext);
    const [genders, setGenders] = useState([
        { id: 1, title: "Male" },
        { id: 2, title: "Female" },
    ]);
    const [open, setOpen] = useState(false)
    const [filterGrade, setFilterGrade] = useState(0);
    const [viewData, setViewData] = useState({});
    const [dob, setDob] = useState(null);
    const theme = useTheme();
    const [filterGender, setFilterGender] = useState(null);
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [selectType, setSelectType] = useState(null);
    const [stateId, setStateId] = useState([]);
    const [boards, setBoards] = useState([]);
    const [gradeFilter, setGradeFilter] = useState(null);
    const [boardFilter, setBoardFilter] = useState(null);
    const [relationFilter, setRelationFilter] = useState(null);
    const [errors, setErrors] = useState({});
    const [countrylistFilter, setcountrylistFilter] = useState(null);
    const [statelistFilter, setstatelistFilter] = useState(null);
    // const [countrylist, setCountryList] = useState([]);
    // const [statelist, setStateList] = useState([]);
    // const [gradeUpd, setGrade] = useState(null);
    // const 
    const [submitValue, setSubmitValue] = useState({
        complete_name: "",
        email: "",
        gender: "",
        grade: {},
        board: {},
    })

    const [addressData, setAddressData] = useState({
        ids: null,
        line_1: "",
        line_2: "",
        landmark: "",
        city: "",
        zipcode: "",
        state: "",
        country: "",
    })
    const [errorObj, setErrorObj] = useState({
        emailErr: false,

    })

    const [relation, setRelation] = useState([
        { id: 1, title: "Father" },
        { id: 2, title: "Mother" },
        { id: 3, title: "Brother" },
        { id: 4, title: "Sister" },
        { id: 5, title: "Uncle" },
        { id: 6, title: "Aunt" },
        { id: 7, title: "Niece" },
        { id: 8, title: "Nephew" },
        { id: 9, title: "Cousin" },
        { id: 10, title: "Grandfather" },
        { id: 11, title: "Grandmother" },
        { id: 12, title: "Other" },
    ]);

    const [guardians, setGuardians] = useState({
        ids: "",
        full_name: "",
        phone: "",
        email: "",
        education: "",
        occupation: "",
        relationship: null,
    })
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            // padding: theme.spacing(2),
            textAlign: "start",
            color: theme.palette.text.secondary,
            boxShadow: " 0 0 5px 0 rgb(0 0 0 / 50%)",
            borderRadius: "9px",
        },
        root: {
            display: "flex",
            flexWrap: "wrap",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: "100%",
        },
        textWidth: {
            width: "86%",
            marginBottom: "10px",
        },
        textWidth2: {
            width: "50%",
            margin: "10px !important",
        },
        mainText: {
            width: "90%",
        },
        textWidth2List: {
            fontSize: " 0.875rem",
            fontWeight: "600",
            lineHeight: "1.75",
            whiteSpace: "normal",
            letterSpacing: " 0.02857em",
        },
    }));
    const classes = useStyles();

    useEffect(() => {
        getGrade();
        getBoard();
        // getCountry();
        // getState();
        getUserData();
    }, [])

    const getGrade = () => {
        login_api
            .get(`/base/grade/`)
            .then((response) => {
                // console.log(response.data.data, "board/grade");
                setStateId(response.data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getBoard = () => {
        login_api
            .get(`/base/board/`)
            .then((response) => {
                setBoards(response.data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    // const getCountry = () => {
    //     login_api
    //         .get(`/profile/country/`)
    //         .then((response) => {
    //             setCountryList(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // }

    // const getState = () => {
    //     login_api
    //         .get(`/profile/state/`)
    //         .then((response) => {
    //             setStateList(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // }

    const handleClickOpen = (number) => {
        setOpen(true);
        setSelectType(number)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleChangeData = (event) => {
        const { name, value } = event.target;
        console.log(name, value, "NAME")
        setSubmitValue({ ...submitValue, [name]: value })


    }

    const handleChangeAddress = (event) => {
        const { name, value } = event.target;
        console.log(name, value, "NAME")
        setAddressData({ ...addressData, [name]: value })
    }

    const handleGender = (event, values) => {
        console.log(event.target.value, values, "events")
        const vals = values !== null ? setSubmitValue({ ...submitValue, gender: values.id }) : "Please select Gender";
        setFilterGender(genders.findIndex((el) => el.id === +values?.id));
        if(!values){
            setErrors({ ...errors, gender: vals });
        }else{
            if(errors.gender){
                delete errors.gender;
                setErrors({...errors})
            }
        }
    };

    const handleChangeGuardian = (e) => {
        const { name, value } = e.target;
        console.log(name, value)
        setGuardians({ ...guardians, [name]: value })
    }

    const handleRelation = (value) => {
        console.log(value, "VALUES")
        setGuardians({ ...guardians, relationship: value.id })
    }

    const handleChangedGrade = (event, values) => {
        console.log("values", values);
        setSubmitValue({ ...submitValue, grade: values })
        // setGrade(values);
        // const outpt = values !== null ? setGrade(values.id) : "Please select Grade";
        // setFilterGrade(stateId.findIndex((el) => el.id === values?.id));
        // setErrors({ ...errors, outpt });
        // console.log("values", gradeUpd);
    };

    const handleChangedBoard = (event, values) => {
        console.log("values", values);
        setSubmitValue({ ...submitValue, board: values })
        // setGrade(values);
        // const outpt = values !== null ? setGrade(values.id) : "Please select Grade";
        // setFilterGrade(stateId.findIndex((el) => el.id === values?.id));
        // setErrors({ ...errors, outpt });
        // console.log("values", gradeUpd);
    };

    const handleChangeCountry = (event, values, names) => {
        console.log("Values", values, event.target, names)
        if(names == "state"){
            setAddressData({ ...addressData, [names]: values?.state })
        }else if (names == "country"){
            setAddressData({ ...addressData, [names]: values?.country })
        }
    }

    const handleChanges = (event, value) => {
        // console.log(event.target.value, "value");

        const output =
            event.target.value !== null
                ? setDob(event.target.value)
                : "Please Select the DOB";

        // setErrors({ ...errors, output });
    };


    const getUserData = () => {
        api_call_token
            .get("/auth/user/me/")
            .then((res) => {
                // debugger;
                const { complete_name, email, gender, profile, address, guardian } = res.data.data;
                const { dob, grade, board } = profile;
                console.log(complete_name, email, gender, "DDDDDDDDDDDDDDDDdd")
                // const { id, line_1, line_2, city, country, landmark, state, zipcode} = address[0];
                setViewData(res?.data?.data);
                let gend = genders.findIndex((el) => el.id === +gender);
                setFilterGender(gend);
                console.log(gender, "gendssssssss")
                //  setGrade(grade);
                setDob(dob)
                // let datas = {
                //     complete_name: '',
                //     email: "",
                //     gender: "",
                // }
                let datas = {
                    complete_name: complete_name, email: email, gender: gender, dob: dob, grade: grade, board: board,
                    // ids: address[0]?.id || null,
                    // line_1: address[0]?.line_1, line_2: address[0]?.line_2,
                    // city: address[0]?.city,
                    // country: address[0]?.country,
                    // landmark: address[0].landmark,
                    // state: address[0].state,
                    // zipcode: address[0].zipcode,
                }
                console.log(datas, board, "HHHHHHH")
                console.log(gender, "GGGGGGG")
                setSubmitValue(datas)
                if (address.length > 0) {
                    setAddressData({
                        ids: address[0]?.id || null,
                        line_1: address[0]?.line_1, line_2: address[0]?.line_2,
                        city: address[0]?.city,
                        country: address[0]?.country,
                        landmark: address[0].landmark,
                        state: address[0].state,
                        zipcode: address[0].zipcode,
                    })

                    let country = countrylist.findIndex((el) => el.country == address[0]?.country);
                    console.log(country, countrylist, address[0]?.country, "Countrys")

                    setcountrylistFilter(countrylist.findIndex((el) => el.country == address[0]?.country))
                    setstatelistFilter(statelist.findIndex((el) => el.state == address[0]?.state))


                }

                if (guardian.length > 0) {
                    setGuardians({
                        ids: guardian[0]?.id,
                        full_name: `${guardian[0].first_name} ${guardian[0].last_name}`,
                        education: guardian[0]?.education,
                        occupation: guardian[0]?.occupation,
                        phone: guardian[0]?.phone,
                        email: guardian[0]?.email,
                        relationship: guardian[0]?.relationship,
                    })
                }

                if (gradeList.length) {
                    // console.log(stateId.findIndex((el) => el.id === user?.profile?.grade?.id),"data");
                    let values = gradeList.findIndex((el) => el.id === user?.profile?.grade?.id)

                    setGradeFilter(values);
                }
                if (guardian[0].relationship) {
                    setRelationFilter(relation.findIndex((el) => el.id === guardian[0].relationship))
                }
                let values = boardList.findIndex((el) => el.id == board?.id);
                setBoardFilter(values);
                // if (board.id) {
                //     debugger;
                //     // console.log(stateId.findIndex((el) => el.id === user?.profile?.grade?.id),"data");

                // }

                // setFilterGender(gend);
            })
            .catch((error) => {
                console.log(error, "Error occurred");
            });
        setLoading(false);
    };


    const SubmitData = () => {
        console.log("Submit Data")

        let data = {};

        if (selectType == 1) {
            let names = submitValue.complete_name.split(' ');
            data = {
                first_name: names[0],
                last_name: names[1],
                email: submitValue.email,
                gender: submitValue.gender,
                profile: {
                    dob: dob,
                }
            }
        }

        if (selectType == 2) {
            data = {
                profile: {
                    grade_id: submitValue?.grade?.id || null,
                    board_id: submitValue?.board?.id || null,
                }
            }
        }

        if (selectType == 3) {
            if (addressData?.ids) {
                data = {

                    address: [
                        {
                            id: addressData?.id,
                            line_1: addressData?.line_1 || "",
                            line_2: addressData?.line_2 || "",
                            landmark: addressData?.landmark || "",
                            city: addressData?.city || "",
                            zipcode: addressData?.zipcode || "",
                            state: addressData?.state || "",
                            country: addressData?.country || ""
                        }
                    ]
                }
            } else {
                data = {

                    address: [
                        {
                            line_1: addressData?.line_1 || "",
                            line_2: addressData?.line_2 || "",
                            landmark: addressData?.landmark || "",
                            city: addressData?.city || "",
                            zipcode: addressData?.zipcode || "",
                            state: addressData?.state || "",
                            country: addressData?.country || ""
                        }
                    ]
                }
            }

        }

        if (selectType == 4) {
            let names = guardians.full_name.split(' ');
            if (guardians?.ids) {
                data = {

                    guardian: [
                        {
                            id: guardians?.ids,
                            first_name: names[0] || "",
                            last_name: names[1] || "",
                            email: guardians?.email || "",
                            phone: guardians?.phone || "",
                            occupation: guardians?.occupation || "",
                            education: guardians?.education || "",
                            relationship: guardians?.relationship
                        }
                    ]
                }
            } else {
                data = {

                    guardian: [
                        {
                            first_name: names[0] || "",
                            last_name: names[1] || "",
                            email: guardians?.email || "",
                            phone: guardians?.phone || "",
                            occupation: guardians?.occupation || "",
                            education: guardians?.education || "",
                            relationship: guardians?.relationship
                        }
                    ]
                }
            }

        }

        console.log(data, "DATAsssssss")

        api_call_token
            .patch(`/auth/v2/register/${user.id}/`, data)
            .then((response) => {
                setUser(response.data.data);
                // setSubmitSuccess(1);
                setViewData(response?.data?.data);
                setOpen(false)

            });
    }


    const Address = () => {
        return (
            <div>
                <div style={{ margin: "auto" }}>
                    <TextField
                        value={`${addressData?.line_1 || ""}`}
                        label="Line1"
                        onChange={handleChangeAddress}
                        placeholder="Line 1"
                        className={classes.textWidth}
                        variant="outlined"
                        // id="outlined-full-width"
                        name="line_1"
                        required
                    // style={{ width: "27em" }}
                    ></TextField>
                    {/* <p className="error-message-text error-marginbottom">
                        {errors.name}
                    </p> */}
                </div>
                <div style={{ margin: "auto" }}>
                    <TextField
                        value={`${addressData?.line_2 || ""}`}
                        label="Line 2"
                        onChange={handleChangeAddress}
                        placeholder="Line 2"
                        className={classes.textWidth}
                        variant="outlined"
                        // id="outlined-full-width"
                        name="line_2"
                        required
                    // style={{ width: "27em" }}
                    ></TextField>
                    {/* <p className="error-message-text error-marginbottom">
                        {errors.name}
                    </p> */}
                </div>
                <div style={{ margin: "auto" }}>
                    <TextField
                        value={`${addressData?.landmark || ""}`}
                        label="landmark"
                        onChange={handleChangeAddress}
                        placeholder="Landmark"
                        className={classes.textWidth}
                        variant="outlined"
                        // id="outlined-full-width"
                        name="landmark"
                        required
                    // style={{ width: "27em" }}
                    ></TextField>
                    {/* <p className="error-message-text error-marginbottom">
                        {errors.name}
                    </p> */}
                </div>
                <div style={{ margin: "auto" }}>
                    <TextField
                        value={`${addressData?.city || ""}`}
                        label="city"
                        onChange={handleChangeAddress}
                        placeholder="City"
                        className={classes.textWidth}
                        variant="outlined"
                        // id="outlined-full-width"
                        name="city"
                        required
                    // style={{ width: "27em" }}
                    ></TextField>
                    {/* <p className="error-message-text error-marginbottom">
                        {errors.name}
                    </p> */}
                </div>
                <div style={{ margin: "auto" }}>
                    <TextField
                        value={`${addressData?.zipcode || ""}`}
                        label="zipcode"
                        onChange={handleChangeAddress}
                        placeholder="Zipcode"
                        className={classes.textWidth}
                        variant="outlined"
                        // id="outlined-full-width"
                        name="zipcode"
                        type="number"
                        required
                    // style={{ width: "27em" }}
                    ></TextField>
                    {/* <p className="error-message-text error-marginbottom">
                        {errors.name}
                    </p> */}
                </div>
                <div style={{ margin: "auto" }}>
                    {/* <TextField
                        value={`${addressData?.state || ""}`}
                        label="state"
                        onChange={handleChangeAddress}
                        placeholder="State"
                        className={classes.textWidth}
                        variant="outlined"
                        // id="outlined-full-width"
                        name="state"
                        required
                    // style={{ width: "27em" }}
                    ></TextField> */}

                    <Autocomplete
                        value={statelist[statelistFilter]}
                        id="combo-box-demo"
                        options={statelist}
                        // style={{ margin: "0 auto" }}
                        onChange={(e, v) => handleChangeCountry(e, v, "state")}
                        className={classes.textWidth}
                        variant="outlined"
                        getOptionLabel={(option) => option.state}
                        renderInput={(params) => (
                            <TextField
                                variant="outlined"
                                {...params}
                                label="State"
                                name="State"
                            />
                        )}
                    />
                    {/* <p className="error-message-text error-marginbottom">
                        {errors.name}
                    </p> */}
                </div>
                <div style={{ margin: "auto" }}>
                    {/* <TextField
                        value={`${addressData?.country || ""}`}
                        label="country"
                        onChange={handleChangeAddress}
                        placeholder="Country"
                        className={classes.textWidth}
                        variant="outlined"
                        // id="outlined-full-width"
                        name="country"
                        required
                    // style={{ width: "27em" }}
                    ></TextField> */}

                    <Autocomplete
                        value={countrylist[countrylistFilter]}
                        id="combo-box-demo"
                        options={countrylist}
                        // style={{ margin: "0 auto" }}
                        onChange={(e, v) => handleChangeCountry(e, v, "country")}
                        className={classes.textWidth}
                        variant="outlined"
                        getOptionLabel={(option) => option.country}
                        renderInput={(params) => (
                            <TextField
                                variant="outlined"
                                {...params}
                                label="Country"
                                name="Country"
                            />
                        )}
                    />
                    {/* <p className="error-message-text error-marginbottom">
                        {errors.name}
                    </p> */}
                </div>
            </div>
        )
    }

    const personalData = () => {
        return (
            <div>
                <div style={{ margin: "auto" }}>
                    <TextField
                        value={`${submitValue?.complete_name}`}
                        label="Full Name"
                        onChange={handleChangeData}
                        placeholder="Full Name"
                        className={classes.textWidth}
                        variant="outlined"
                        // id="outlined-full-width"
                        name="complete_name"
                        required
                        style={{ width: "300px" }}
                    ></TextField>
                    {/* <p className="error-message-text error-marginbottom">
                        {errors.name}
                    </p> */}
                </div>
                <div>
                    <TextField
                        name="email"
                        className={classes.textWidth}
                        variant="outlined"
                        value={submitValue?.email}
                        onChange={handleChangeData}
                        placeholder="Email address"
                        label="Email address"
                        style={{ width: "300px" }}
                        inputProps={{
                            pattern:
                                "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$",
                            endadornment: (
                                <InputAdornment position="center">
                                    {errorIcon}
                                </InputAdornment>
                            ),
                        }}
                    ></TextField>
                    {/* <p className="error-message-text error-marginbottom">
                        {errors.email}
                    </p> */}
                </div>
                <div>
                    <Autocomplete
                        value={genders[filterGender]}
                        id="combo-box-demo"
                        options={genders}
                        // style={{ margin: "0 auto" }}
                        onChange={handleGender}
                        className={classes.textWidth}
                        variant="outlined"
                        getOptionLabel={(option) => option.title}
                        renderInput={(params) => (
                            <TextField
                                variant="outlined"
                                {...params}
                                label="Gender"
                                name="gender"
                                error={errors?.gender}
                                style={{ width: "300px" }}
                            />
                        )}x
                    />
                    <p className="error-message-text error-marginbottom">
                        {errors?.gender}
                    </p>
                </div>
                <div>
                    <div>
                        <TextField
                            value={dob}
                            className={classes.textWidth}
                            variant="outlined"
                            label="Date of Birth"
                            type="date"
                            onChange={handleChanges}
                            defaultValue="2000-01-01"
                            style={{ width: "300px" }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required
                            id="outlined-full-width"
                            name="birthday"
                        />
                    </div>
                </div>

            </div>
        )
    }

    const selectBoard = () => {
        return (
            <div>
                <div>
                    <Autocomplete
                        // value={stateId[filterGrade]}
                        id="combo-box-demos"
                        value={stateId[gradeFilter]}
                        options={stateId}
                        style={{ margin: "10px auto" }}
                        onChange={handleChangedGrade}
                        className={classes.textWidth}
                        getOptionLabel={(option) => option.title}
                        renderInput={(params) => (
                            <TextField
                                required
                                variant="outlined"
                                {...params}
                                label="Grade"
                                name="grade"
                            />
                        )}
                    />
                </div>
                <div>
                    <Autocomplete
                        // value={stateId[filterGrade]}
                        value={boardList[boardFilter]}
                        id="combo-box-demos"
                        options={boards}
                        style={{ margin: "10px auto" }}
                        onChange={handleChangedBoard}
                        className={classes.textWidth}
                        getOptionLabel={(option) => option.title}
                        renderInput={(params) => (
                            <TextField
                                required
                                variant="outlined"
                                {...params}
                                label="Board"
                                name="board"
                            />
                        )}
                    />
                </div>


            </div>
        )
    }

    const guardianInfo = () => {
        return (
            <div>
                <div className="">
                    <TextField
                        name="full_name"
                        value={guardians?.full_name || ""}
                        // onChange={handleChangeData}
                        onChange={(e) => handleChangeGuardian(e)}
                        placeholder="Parents Full Name"
                        label="Parents Full Name"
                        className={classes.textWidth}
                        variant="outlined"
                        id="outlined-full-width"
                        required
                    ></TextField>
                </div>
                <div className="">
                    <TextField
                        name="phone"
                        value={guardians?.phone || ""}
                        // onChange={handleChangeData}
                        onChange={(e) => handleChangeGuardian(e)}
                        placeholder="Phone No."
                        label="Phone No."
                        className={classes.textWidth}
                        variant="outlined"
                        id="outlined-full-width"
                        type="number"
                        inputProps={{ maxLength: 10 }}
                        required
                    ></TextField>
                    {/* email */}
                    <TextField
                        name="email"
                        value={guardians?.email || ""}
                        // onChange={handleChangeData}
                        onChange={(e) => handleChangeGuardian(e)}
                        placeholder="Email address"
                        label="Email address"
                        className={classes.textWidth}
                        variant="outlined"
                        id="outlined-full-width"
                    ></TextField>
                </div>
                <div className="">
                    <TextField
                        name="occupation"
                        value={guardians?.occupation}
                        // onChange={handleChangeData}
                        onChange={(e) => handleChangeGuardian(e)}
                        placeholder="Occupation"
                        label="Occupation"
                        className={classes.textWidth}
                        variant="outlined"
                        id="outlined-full-width"
                        type="text"
                    ></TextField>

                    <TextField
                        name="education"
                        value={guardians?.education}
                        // onChange={handleChangeData}
                        onChange={(e) => handleChangeGuardian(e)}
                        placeholder="Education"
                        label="Education"
                        className={classes.textWidth}
                        variant="outlined"
                        id="outlined-full-width"
                    ></TextField>
                </div>
                <div>
                    <Autocomplete
                        id="combo-box-demo"
                        value={relation[relationFilter]}
                        options={relation}
                        //   style={{ margin: "0 auto" }}
                        onChange={(e, value) => handleRelation(value)}
                        className={classes.textWidth}
                        getOptionLabel={(option) => option.title}
                        renderInput={(params) => (
                            <TextField
                                required
                                variant="outlined"
                                {...params}
                                label="Relation"
                                name="relation"
                            />
                        )}
                    />
                </div>
            </div>
        )
    }
    console.log(viewData, user,statelist[statelistFilter],  "ViewDATA")
    return (
        <div
            className="op-min"
            style={{
                minHeight: "100vh",
                position: "relative",
                overflow: "hidden",
                display: "flex",
                padding: "80px 0px",
            }}
        >
            <Container maxWidth="lg">
                <div id="box">
                    <h2 className="accountTitle">My Account</h2>
                    <div className="">
                        {!loading && (
                            <Grid container spacing={5}>
                                <Grid item xs={12} md={3}>
                                    <Box boxShadow={3} className={classes.paper}>
                                        <Grid item xs={12} className="cardHeight">
                                            <Paper container className={"firstSection"} spacing={2}>
                                                <Grid spacing={2} className={"cardTitle"}>
                                                    <img
                                                        src={avatarIcon}
                                                        alt="avatarIcon"
                                                        style={{ width: "30px", height: "auto" }}
                                                    />
                                                    <p>Personal Details</p>
                                                </Grid>

                                                <IconButton aria-label="edit"
                                                    onClick={() => handleClickOpen(1)}
                                                >
                                                    <EditOutlined style={{ color: "#EF9000" }} />
                                                </IconButton>
                                            </Paper>
                                            <div className="userCardDetails">
                                                <p>{viewData?.complete_name}</p>
                                                <p>{viewData?.phone}</p>
                                                <p>
                                                    {(user?.gender == "1") ? "Male" : "Female"}
                                                    {/* {console.log("dsfsfsd",genders[viewData?.profile.genders].title)}  {console.log(genders[viewData.gender - 1].title, "genders")} */}
                                                </p>
                                                <p>{viewData?.email}</p>
                                                <p>{viewData?.profile?.dob}</p>
                                            </div>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Paper className={classes.paper}>
                                        <Grid item xs={12} className="cardHeight">
                                            <Paper className={"firstSection"} spacing={2}>
                                                <Grid spacing={2} className={"cardTitle"}>
                                                    <img
                                                        src={badgeIcon}
                                                        alt="badgeIcon"
                                                        style={{ width: "30px", height: "auto" }}
                                                    />
                                                    <p>Class & Board</p>
                                                </Grid>

                                                <IconButton
                                                    aria-label="edit"
                                                    onClick={() => handleClickOpen(2)}
                                                >
                                                    <EditOutlined style={{ color: "#EF9000" }} />
                                                </IconButton>
                                            </Paper>
                                            <div className="userCardDetails">
                                                <p>{user?.profile?.grade?.title}</p>
                                                <p>{user?.profile?.board?.title}</p>
                                            </div>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Paper className={classes.paper}>
                                        <Grid item xs={12} className="cardHeight">
                                            <Paper className={"firstSection"} spacing={2}>
                                                <Grid spacing={2} className={"cardTitle"}>
                                                    <div />
                                                    <p>Address</p>
                                                </Grid>
                                                <IconButton
                                                    aria-label="edit"
                                                    onClick={() => handleClickOpen(3)}
                                                >
                                                    <EditOutlined style={{ color: "#EF9000" }} />
                                                </IconButton>

                                            </Paper>
                                            <div className="userCardDetails">
                                                <p>{viewData?.address?.[0]?.line_1}</p>
                                                <p>{viewData?.address?.[0]?.line_2}</p>

                                                <p>{viewData?.address?.[0]?.landmark}</p>

                                                <p>{viewData?.address?.[0]?.city}</p>
                                                <p>{viewData?.address?.[0]?.state}</p>
                                                <p>{viewData?.address?.[0]?.country}</p>
                                                <p>{viewData?.address?.[0]?.zipcode}</p>


                                            </div>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Paper className={classes.paper}>
                                        <Grid item xs={12} className="cardHeight">
                                            <Paper className={"firstSection"} spacing={2}>
                                                <Grid spacing={2} className={"cardTitle"}>
                                                    <img
                                                        src={guardianIcon}
                                                        alt="guardianIcon"
                                                        style={{ width: "30px", height: "auto" }}
                                                    />
                                                    <p>Guardian Info</p>
                                                </Grid>
                                                <IconButton
                                                    aria-label="edit"
                                                    onClick={() => handleClickOpen(4)}
                                                //   onClick={handleClickOpenGurd}
                                                >
                                                    <EditOutlined style={{ color: "#EF9000" }} />
                                                </IconButton>
                                            </Paper>
                                            <div className="userCardDetails">
                                                {viewData?.guardian
                                                    ? viewData?.guardian.map((gardianData, id) => (
                                                        <>
                                                            <p>
                                                                {`${gardianData?.first_name} ${gardianData?.last_name}`}
                                                            </p>
                                                            <p>{gardianData?.phone}</p>
                                                            <p>{gardianData?.email}</p>
                                                            <p>{gardianData?.occupation}</p>
                                                            <p>{gardianData?.education}</p>
                                                        </>
                                                    ))
                                                    : null}

                                            </div>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        )}
                        {loading && (
                            <Grid container spacing={5}>
                                {[1, 2, 3, 4].map((v, i) => (
                                    <Grid item xs={12} md={3}>
                                        <div style={{ background: "white" }}>
                                            <Skeleton animation="wave" />
                                            <Skeleton
                                                animation={false}
                                                style={{ height: "30px", padding: "0" }}
                                            />
                                            <Skeleton animation="wave" style={{ height: "80px" }} />
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </div>
                </div>
            </Container>

            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle
                    xs={12}
                    id="responsive-dialog-title"
                    style={{ background: "#172b69" }}
                    className={"profileDailogTitle"}
                >
                    <img
                        src={avatarIcon}
                        alt="avatarIcon"
                        style={{ width: "30px", height: "auto" }}
                    />
                    <p>{(selectType == 1) ? "Personal Details" : (selectType == 2) ? "Class & Board" : (selectType == 3) ? "Address" : "Guardian Info"}</p>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {selectType == 1 && personalData()}
                        {selectType == 2 && selectBoard()}
                        {selectType == 3 && Address()}
                        {selectType == 4 && guardianInfo()}

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button
                        className="btn_cancel"
                        autoFocus
                        onClick={handleClose}
                        color="primary"
                    >
                        Cancel
                    </button>
                    <button
                        className="btn_cancel btn_save"
                        onClick={SubmitData}
                        color="primary"
                        autoFocus
                    >
                        Save
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default NewMyAccount