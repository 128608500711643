import React, { useState, useEffect, useContext } from "react";
import styles from "./index.module.css";
import supp1 from "../../Assets/HomeRewamp/sir.jpeg";
import supp2 from "../../Assets/HomeRewamp/supportBy2.png";
import IITBombay from "../../Assets/institute/IITBombay.png";
import insterst_form_img from "../../Assets/insterst_form.png";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { useHistory } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { api_call, api_call_token } from "../../Utils/Network";
import Popovr from "../../Components/Popover/Popovr";
import Brains from "../../Assets/brainhap/heroBrainhap.png";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Alert } from "@material-ui/lab";
import Modal from "@material-ui/core/Modal";
// ---------------------
import userF from "../../Assets/HomeRewamp/user1.png";
import ArrowForwardSharpIcon from "@material-ui/icons/ArrowForwardSharp";
import serviceCard1 from "../../Assets/brainhap/serviceCard1.png";
import girl_Illustrator from "../../Assets/brainhap/girl_Illustrator.svg";
import skills_background from "../../Assets/brainhap/skills_background.svg";
import blueabstract from "../../Assets/brainhap/blueabstract.png";
import messages from "../../Assets/brainhap/messages.svg";
import message_icon from "../../Assets/brainhap/message_icon.svg";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";

import NewCarousel from "../../Components/Carousels/newCarousel";
import AOS from "aos";
import "aos/dist/aos.css";
import { Box, Typography } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";

import sklcloud from "../../Assets/brainhap/Group 34 (1).png";

function HomeRewamp() {
  AOS.init();
  const history = useHistory();
  const { setDisplayType, setOpenModel, user } = useContext(UserCredsContext);
  const [testType, setTestType] = useState(null);
  const [value, setValue] = React.useState(3);
  const [benefits, setBenefits] = useState({});
  // const [benefitsCard, setBenefitsCard] = useState(["key": "value"; "key"="value"]);
  const [feature, setFeature] = useState([]);
  const [schools, setSchools] = useState([]);
  const [testimonials, setTestimonials] = useState({});
  const [testimonialData, setTestimonialData] = useState([]);
  const [joins, setJoin] = useState([]);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [interestUserData, setInterestUserData] = useState({
    name: "",
    mobile_number: null,
    city: "",
  });
  const handleanimation = (e) => {
    setShow(!show);
    console.log(e);
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getDatas();
    getJoinDetails();
  }, []);

  const getDatas = () => {
    api_call.get(`webhome/homescreen/`).then((res) => {
      setBenefits(res.data.data[0].data[0]);
      setFeature(res.data.data[1].data);
      setTestimonials(res.data.data[2].data[0]);
      setTestimonialData(res.data.data[2].data[0].testimonial_elements);
      setSchools(res.data.data[3]);
      // console.log("setSchools", schools);
    });
  };

  const basic_detail = (vals) => {
    if (vals === 1) {
      if (user.user_type === 1) {
        history.push("/ask-to-counselor");
      } else {
        setDisplayType(0);
        setOpenModel(true);
      }
    } else if (vals === 4) {
      setOpen(true);
    } else if (vals === 3) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.lcarro",
        "_blank"
      );
    } else if (vals === 2) {
      if (user.user_type === 1) {
        // history.push('/taketest')
        alert("already logged in");
      } else {
        setDisplayType(0);
        setOpenModel(true);
      }
    }
  };

  const getJoinDetails = () => {
    api_call
      .get(`webhome/basic_details/`)
      .then((res) => {
        setJoin(res.data.data);
      })
      .catch((err) => console.log(err));
  };
  const redirects = () => {
    if (user.user_type === 1) {
      // history.push('/taketest')
      history.push("/testseries");
    } else {
      setDisplayType(0);
      setOpenModel(true);
      setTestType(1);
    }
  };

  const handleChangeData = (event) => {
    let temp = {};
    const { name, value } = event.target;
    console.log(name, value, "ddds");

    let returnValue = true;
    let nameTest = /^[a-zA-Z ]{2,30}$/;

    temp.name = nameTest.test(interestUserData.name)
      ? ""
      : "Please enter valid name.";
    temp.city = nameTest.test(interestUserData.city)
      ? ""
      : "Please enter valid name.";

    temp.mobile_number =
      /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(
        interestUserData?.mobile_number
      )
        ? interestUserData?.mobile_number.length > 10 ||
          interestUserData?.mobile_number.length < 10
          ? "Please Enter valid phone number"
          : ""
        : "Please Enter valid phone number";
    let new_data = { ...interestUserData, [name]: value };
    console.log(new_data, "new Data");
    setInterestUserData(new_data);
    setErrors({
      ...temp,
    });
    const found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("required") || temp[key].includes("Please");
    });

    if (found.length) {
      returnValue = false;
    }
    return returnValue;
  };

  const SubmitData = () => {
    // if (!handleChangeData()) {
    //   return errors;
    // }
    let returnValue = true;
    const found = Object.keys(errors).filter(function (key) {
      return errors[key].includes("required") || errors[key].includes("Please");
    });

    if (found.length) {
      returnValue = false;
    }

    api_call_token
      .post("/test/Interest_brainMapping/", interestUserData)
      .then((res) => {
        console.log(res, "res res");
        <Alert variant="outlined" severity="success">
          Form success Submitted!
        </Alert>;
        setOpen(false);
        // history.push("/testseries");
      })
      .catch((err) => {
        <Alert color="error" closeText="close" variant="outlined" />;

        console.log(err);
      });
  };
  // const {user} = useContext(UserCredsContext);
  // console.log(benefits, "benefits");

  const handleClose = () => {
    setOpen(false);
  };

  const isMobile = () =>
    window.matchMedia && window.matchMedia("(max-width: 480px)").matches;
  console.log("isMobile---", isMobile);

  return (
    <>
      <div style={{ position: "relative" }}>
        {/* ======== herooo section ======== */}
        <div
          className={styles.light_blue_gradident}
          style={{
            position: "relative",
          }}
        >
          <div className={styles.main_width}>
            <div
              className={`${styles.headingConten} ${styles.pt100} ${styles.ptbt} `}
            >
              <div className={`${styles.box_content}`}>
                <div>
                  <h1 className={`${styles.white} ${styles.max_font}`}>
                    <span style={{ color: "white" }}>
                      Connecting &nbsp;your&nbsp;{" "}
                    </span>{" "}
                    <br></br>
                    <span
                      className={`${styles.blue_text} ${styles.animatedText}  ${styles.max_font}`}
                    >
                      Brain&nbsp;
                    </span>
                    <span style={{ color: "white" }}>and</span>
                    <span
                      className={`${styles.blue_text} ${styles.animatedText} ${styles.max_font}`}
                    >
                      &nbsp;Career
                    </span>
                  </h1>

                  <p
                    className={`${styles.subtitles_text} ${styles.white} ${styles.subTittleFont}`}
                    style={{ marginTop: "40px" }}
                  >
                    Empowering young individuals to achieve excellence with
                    <span className={styles.blue_text}>
                      {" "}
                      Neurotechnology{" "}
                    </span>{" "}
                    .
                  </p>
                </div>
                <button
                  style={{ marginTop: "60px" }}
                  className={`${styles.btn_struct} ${styles.btn_blue_gradident}`}
                  onClick={() => {
                    redirects();
                  }}
                >
                  Take The Test <ArrowForwardSharpIcon />
                </button>
              </div>
              <div
                className={`${styles.box_content}`}
                style={{ textAlign: "end" }}
              >
                <img className={styles.videos} src={Brains} alt="headers" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ======== slider section ======== */}
      <div className={`${styles.sliderDivBg} `}>
        <div className={`${styles.maxWidth} ${styles.sliderDivBgChild}`}>
          <div className={styles.sliderContent}>
            <div className={styles.sliderlogoDiv}>
              <img src={IITBombay} alt="IITBombay" />
              <div className={styles.bluiesh} style={{ padding: "10px 2.5vw" }}>
                <div className={styles.mid_font}>In collaboration with</div>
                <div className={styles.mx_fnt}>IIT Bombay</div>
              </div>
            </div>
            <div className={styles.sliderHr}></div>
          </div>
          <div style={{ width: "inherit", margin: "auto 19px" }}>
            <p
              style={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                textAlign: "center",
                color: "rgba(3, 44, 107, 0.76)",
                margin: "0 0 10px 0",
              }}
            >
              Our School Partner
            </p>
            <marquee>
              {schools?.data?.map((data, indx) => (
                <>
                  {console.log(data, "data")}
                  <img
                    key={indx}
                    src={data?.banner}
                    // alt="icon"
                    style={{ padding: "0 24px" }}
                  />
                </>
              ))}
            </marquee>
          </div>
        </div>
      </div>

      {/* ======== copyied services ======== */}

      <div className={styles.bottom_rightGradient}>
        <div className={`${styles.main_width}  ${styles.mobile_container}`}>
          <div
            className={styles.aaa}
            style={{ textAlign: "center", margin: "100px auto" }}
          >
            <h1
              className={`${styles.bluieshSmall} ${styles.max_font} text-capitalize `}
              style={{ marginBottom: "20px" }}
            >
              {benefits?.title}
            </h1>
            <p
              className={`${styles.greyesh} ${styles.min_font} ${styles.line_he}`}
            >
              {benefits?.description}
            </p>
          </div>
          <div className={`${styles.careermapping} ${styles.reveal}`}>
            {benefits?.benefits &&
              benefits?.benefits.map((val, indx) => (
                <>
                  <div
                    key={indx}
                    data-aos={`fade-${indx % 2 === 0 ? "right" : "left"}`}
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                    className={`${styles.benefContent}`}
                    style={{ background: val?.colorcode }}
                  >
                    <h3 className={`${styles.cardTitle} ${styles.btm_marg}`}>
                      <img src={val?.icon2} alt="icons" /> {val?.title}
                    </h3>
                    <div>
                      <img
                        className={`${styles.img_fluid} ${styles.cardimg}`}
                        src={val?.icon1}
                        alt="imasadge"
                      />
                    </div>
                    <div>
                      <p
                        className={`${styles.whiteColor} ${styles.cardSubtitle}`}
                      >
                        {val?.description}
                      </p>
                    </div>
                  </div>
                </>
              ))}
          </div>

          <div className={styles.btnContainer}>
            <button
              className={`${styles.btn_struct} ${styles.btn_blue_gradident}`}
              onClick={() => {
                redirects();
              }}
            >
              Take The Test <ArrowForwardSharpIcon />
            </button>
          </div>
        </div>
      </div>

      <div className={styles.bottom_rightGradient}>
        <div className={`${styles.main_width} ${styles.improve_main}`}>
          <div className={`${styles.flex_content} ${styles.box_content}`}>
            <div
              className={`${styles.improve_Container} ${styles.improve_Container_bg}`}
              // style={{ backgroundImage: ` url("${skills_background}") ` }}
            >
              {/* <img src={sklcloud} /> */}
              {/* <div className={styles.improve_title}> */}

              <Box sx={{ marginTop: "70px", marginLeft: "35px !important" }}>
                <Typography className={styles.typColorBig}>
                  Skill based Education
                </Typography>
                <Typography className={styles.typoColor}>
                  Get a customized tests from the pool of 10,000+ questions
                  having multiple parameters specifically created for Indian
                  students targeting different age groups to measure their
                  behavioural aspects and provide career counselling and
                  guidance for course corrections.
                </Typography>
              </Box>

              {/* <h3 className={`${styles.bluiesh} ${styles.skillsTitle}`} >Skill based Education</h3>

              <p className={`${styles.greyesh} ${styles.improve_para}`}>
                Get a customized tests from the pool of 10,000+ questions having
                multiple parameters specifically created for Indian students
                targeting different age groups to measure their behavioural
                aspects and provide career counselling and guidance for course
                corrections.
              </p> */}
              {/* </div> */}
            </div>
            <div
              className={styles.improve_Container}
              style={{ alignSelf: "center", width: "30%" }}
            >
              <img
                className={styles.img_fluid}
                src={girl_Illustrator}
                alt="improve"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className={styles.btnContainer}>
            <button
              className={`${styles.btn_struct} ${styles.btn_blue_gradident}`}
              onClick={() => {
                redirects();
              }}
            >
              Take The Test <ArrowForwardSharpIcon />
            </button>
          </div>
        </div>
      </div>

      <div className={styles.bottom_rightGradientTop}>
        <div
          className={`${styles.main_width} ${styles.support_main}  ${styles.mobile_container} ${styles.maxWidth}`}
        >
          <div className={`${styles.textCenter}`}>
            <h3 className={`${styles.bluiesh} ${styles.featuresdiv}`}>
              Features
            </h3>
          </div>
          <div className={`${styles.featuresSections} ${""}`}>
            {feature !== null &&
              feature.map((v, i) => (
                <div className={styles.featuresSectionDiv}>
                  <img
                    data-aos={`fade-${i % 2 === 0 ? "right" : "left"}`}
                    data-aos-duration="1000"
                    src={v?.gif}
                    alt="img"
                    className={styles.gifImage}
                  />
                  <div
                    data-aos={`fade-${i % 2 === 0 ? "left" : "right"}`}
                    data-aos-duration="1000"
                    className={`col ${styles.featuresCards}`}
                    key={i}
                    style={{ marginBottom: "20px" }}
                  >
                    <div>
                      <p
                        className={`${styles.black} ${styles.improve_para}`}
                        style={{ margin: "0", fontWeight: "600" }}
                      >
                        {v.title}
                      </p>
                      <p
                        className={`${styles.greyesh} ${styles.improve_para}`}
                        style={{ margin: "0" }}
                      >
                        {v.description}
                      </p>
                    </div>
                    {v?.icon ? (
                      <img
                        style={{ paddingInline: "2.5vw" }}
                        src={v?.icon}
                        alt="img"
                      />
                    ) : null}
                    {/* <img style={{ paddingInline: "20px" }} src={userF} alt="img" /> */}
                  </div>
                </div>
              ))}
          </div>
          <div className={styles.btnContainer}>
            <button
              className={`${styles.btn_struct} ${styles.btn_blue_gradident}`}
              onClick={() => {
                redirects();
              }}
            >
              Take The Test <ArrowForwardSharpIcon />
            </button>
          </div>
        </div>
      </div>

      <div className={` ${styles.mobile_container} ${styles.maxWidth}`}>
        <div className={`${styles.textCenter}`}>
          <h3 className={`${styles.bluiesh} ${styles.featuresdiv}`}>
            Reviews from Our Partner Schools
          </h3>
        </div>
        <NewCarousel sqd={[testimonialData]}></NewCarousel>
      </div>

      <div className={styles.circle_gradient}>
        <div
          className={`${styles.main_width} ${styles.support_main} ${styles.mobile_container}`}
          style={{ marginTop: "100px" }}
        >
          <div
            data-aos={`fade-down`}
            data-aos-duration="900"
            style={{ textAlign: "center", fontSize: "28px" }}
          >
            <h3 className={`${styles.bluiesh} ${styles.featuresdiv}`}>
              Supported By <span className={styles.organge}>Research</span>
            </h3>
          </div>
          <div data-aos={`fade-down`} data-aos-duration="1000">
            <p
              className={`${styles.greyesh} ${styles.supportPara}`}
              style={{ textAlign: "center" }}
            >
              Brainhap tests are created under the supervision of IIT Bombay. Dr.
              Azizuddin Khan (Scientific Advisor and Mentor) with more than 20
              years of experience in Cognitive Psychology.
            </p>
          </div>
          <div
            className={`${styles.flex_content} ${styles.box_content} ${styles.research_content}`}
          >
            <img
              src={supp1}
              data-aos={`fade-right`}
              data-aos-duration="1000"
              alt="support"
              className={styles.improve_Container}
              style={{ margin: "20px", borderRadius: "23px" }}
            />
            <img
              data-aos={`fade-left`}
              data-aos-duration="1000"
              src={supp2}
              alt="support"
              className={styles.improve_Container}
            />
          </div>

          <div className={styles.btnContainer}>
            <button
              className={`${styles.btn_struct} ${styles.btn_blue_gradident}`}
              onClick={() => {
                redirects();
              }}
            >
              Take The Test <ArrowForwardSharpIcon />
            </button>
          </div>
        </div>
      </div>

      {/* ======== Our Services ======== */}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          alignItems: "center",
          justifyContent: "center",
          maxHeight: "100vh",
        }}
      >
        <div className={"styles"}>
          <div className={styles.interest_form}>
            <img
              src={insterst_form_img}
              alt="Inserts Form"
              style={{ width: "45%", height: "auto" }}
            />

            <div>
              <div>
                <p
                  style={{
                    fontFamily: "Segoe UI",
                    fontWeight: "700",
                    fontSize: "3.5vw",
                    lineHeight: "69px",
                    letterSpacing: " 0.05em",
                    textTransform: "capitalize",
                    color: "#3f51b5",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  love to hear from you
                </p>
                <p
                  style={{
                    fontFamily: "Segoe UI",
                    fontWeight: "400",
                    fontSize: "2vw",
                    lineHeight: "53px",
                    letterSpacing: " 0.05em",
                    textTransform: "capitalize",
                    color: "#00000050",
                    margin: "0 0 60px 0",
                    padding: 0,
                  }}
                >
                  get in touch
                </p>
                {/* <AlertMessageComponent open={open} /> */}
                <TextField
                  className={styles.textWidth}
                  value={interestUserData.name}
                  // label="Full Name"
                  onChange={(e) => handleChangeData(e)}
                  placeholder="Full Name"
                  variant="outlined"
                  id="outlined-full-width"
                  name="name"
                  required
                  style={{ width: "27em" }}
                ></TextField>
                <p className="error-message-text error-marginbottom">
                  {errors.name}
                </p>
              </div>
              <div className="">
                <TextField
                  name="mobile_number"
                  value={interestUserData.mobile_number}
                  // onChange={handleChangeData}
                  onChange={(e) => handleChangeData(e)}
                  placeholder="Phone No."
                  // label="Phone No."
                  // className={classes.textWidth}
                  className={styles.textWidth}
                  variant="outlined"
                  id="outlined-full-width"
                  type="number"
                  required
                  style={{ width: "27em" }}
                ></TextField>
                <p className="error-message-text error-marginbottom">
                  {errors.mobile_number}
                </p>
              </div>
              <div>
                <TextField
                  style={{ width: "27em" }}
                  className={styles.textWidth}
                  name="city"
                  value={interestUserData.city}
                  onChange={(e) => handleChangeData(e)}
                  placeholder="City"
                  label="City"
                  variant="outlined"
                  id="outlined-full-width"
                ></TextField>
                <p className="error-message-text error-marginbottom">
                  {errors.city}
                </p>
              </div>

              <Button
                variant="contained"
                className="btn_model"
                onClick={() => {
                  SubmitData();
                  console.log(interestUserData, "interestUserData");
                }}
                color="primary"
                style={{
                  borderRadius: "12px",
                  width: "auto",
                  fontSize: "18px",
                  fontWeight: "550",
                }}
                // disabled={enable}
              >
                Submit
                {/* {guardianExists !== 0 ? "Update Profile" : "Submit"} */}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {/* interest form */}

      <div
        style={{ position: "relative" }}
        className={` ${styles.support_main}  ${styles.mobile_container} ${styles.interestForm} ${styles.alignFlexStart}`}
      >
        {/* msg icon */}

        <div className={`${styles.subscribeDiv}`}>
          <div
            style={{
              display: "inline-block",
              position: "absolute",
              top: "-26%",
              left: "-120px",
            }}
          >
            <img
              // className={`${styles.subscribeImg} ${styles.messageIcon}`}
              // className={`${styles.message_icon} ${styles.subscribeImg} `}
              alt=""
              src={messages}
            />
          </div>

          <div   style={{
              display: "inline-block",
              position: "absolute",
              
              right:'-8%',
              top:'63%'
            }} 
            className={styles.emailresponsive }
            >
            <img src={message_icon} alt="" />
          </div>
          <h1 className={`${styles.subscribeTitle} ${styles.textAtStart}`}>
            Join BrainHap
          </h1>
          <div>
            <p
              className={`${styles.subscribeTitle} ${styles.textAtStart}`}
              style={{fontSize: "18px" }}
            >
              Be the first one to get to know about BrainHap
            </p>
          </div>
          <div
            className={`${styles.formSections}  ${styles.support_main} ${styles.alignFlexStart}`}
          >
            <div>
              <TextField
                autoComplete="off"
                className={styles.textWidth}
                value={interestUserData.name}
                // label="Enter your name"
                onChange={(e) => handleChangeData(e)}
                placeholder="Enter Your Name"
                variant="filled"
                id="filled-basic"
                name="name"
                size="small"
                required
                // sx={{ padding:"0px !important" }}
                sx={{ paddingBottom: "25px !important" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon style={{ color: "#2a619580" }} />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
              {errors.name ? (
                <p className="error-message-text error-marginbottom">
                  {errors?.name}
                </p>
              ) : null}
            </div>
            <div style={{ color: "#2a619580" }}>
              <TextField
                autoComplete="off"
                name="mobile_number"
                value={interestUserData.mobile_number}
                onChange={(e) => handleChangeData(e)}
                placeholder="Enter Your Number"
                // label="Phone No."
                size="small"
                className={styles.textWidth}
                variant="filled"
                id="full-basic"
                type="number"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon style={{ color: "#2a619580" }} />
                    </InputAdornment>
                  ),
                }}
              />

              {errors.mobile_number ? (
                <p className="error-message-text error-marginbottom">
                  {errors?.mobile_number}
                </p>
              ) : null}
            </div>

            <button
              style={{
                textAlign: "center",
              }}
              className={`${styles.joinSubscribeBtn}`}
              onClick={() => {
                redirects();
              }}
            >
              Join
            </button>
            {/* <p className="error-message-text error-marginbottom"></p> */}
          </div>
        </div>

        <div
          className={`${styles.main_width} ${styles.support_main} ${styles.btm_none}`}
        >
          <div style={{ display: "flex" }}>
            {window.innerWidth > 992 ? null : (
              <div style={{ margin: "0 auto -42px", zIndex: "1" }}>
                <Popovr />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeRewamp;
