import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { api_call_token } from "../../Utils/Network";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./TestSeries.module.css";
import Passage from "./Passage";
import ViewResult from "./ViewResult.js";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import StopWatch from "../../Components/StopWatch/StopWatch";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Skeleton } from "@material-ui/lab";
import clock from "../../Assets/test/np-timer.png";
import Modal from "@material-ui/core/Modal";
import { useHistory } from "react-router-dom";
import { Timer } from "@material-ui/icons";
import Stack from "@mui/material/Stack";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 45;
  const left = 45;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "60px",
    border: "0",
    borderRadius: "7px",
    ["@media (max-width: 568px)"]: {
      width: "90vw",
      padding: "20px",
    },
    ["@media (min-width: 768px)"]: {
      width: "400px",
      padding: "20px",
    },
  },
  BackdropProps: {
    width: "500px",
    ["@media (max-width: 768px)"]: {
      width: "100%",
    },
  },
}));

function SingleChoice(props) {
  let { test_type, block_id } = useParams();
  const [state, setState] = React.useState(false);
  const [question, setQuestion] = useState([]);
  const [number, setNumber] = useState(0);
  const [answer, setAnswer] = useState([]);
  const [filterObj, setFilterObj] = useState({});
  const [sub, setSub] = useState(false);
  const [block, setBlock] = useState(null);
  const [totalQ, setTotalQuestion] = useState(null);
  const classes = useStyles();
  const [goToTest, setGoTo] = useState(0);
  const [page, setPages] = useState(1);
  const [time, setTime] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [passageRedirect, setPassageRedirect] = useState(0);
  const [modalStyle] = React.useState(getModalStyle);

  const [showPassage, setShowPassage] = useState(null);
  const [nums, setNums] = useState(0);
  const [getBack, setGetBack] = useState(false);
  const { loading = false } = props;

  const {
    user,
    loginData,
    setStudentProfile,
    setToken,
    updateLoginData,
    setUser,
    guardianExists,
    setGuardianExists,
  } = useContext(UserCredsContext);

  let history = useHistory();
  const interval = React.useRef();
  localStorage.setItem("timer", time);
  React.useEffect(() => {
    interval.current = setInterval(
      () => setTime((prevTimer) => prevTimer - 1),
      1000
    );
  }, []);

  if (time === 0) {
    clearInterval(interval.current);
  }
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  let char = ["A", "B", "C", "D", "E"];

  const retrievehrs = JSON.parse(localStorage.getItem("timer"));
  let hours = Math.floor(retrievehrs / 3600); // get hours
  let minutes = Math.floor((retrievehrs - hours * 3600) / 60); // get minutes
  let seconds = retrievehrs - hours * 3600 - minutes * 60; //  get seconds

  // setTimeout(() => (time == 0 ? SubmitTest() : "in"), 50000);

  const QuitTest = () => {
    getAnswer();
    localStorage.removeItem("timer");
    localStorage.removeItem("answer");
    history.push("/testseries");
  };

  const getAnswer = () => {
    // const retrievehrs = JSON.parse(localStorage.getItem("hours"));
    // const retrievemin = JSON.parse(localStorage.getItem("minutes"));
    // const retrievesec = JSON.parse(localStorage.getItem("seconds"));
    api_call_token
      .patch(`test/get_json/${block}/?test_type=${test_type}`, {
        json: answer,
        time_left: `${hours}:${minutes}:${seconds}`,
      })
      .then((res) => {
        setAnswer(res.data.data);
        setTimers(res.data.data.time_left);
        console.log(`${hours}:${minutes}:${seconds}`, "time_left ");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getNewQuestion = (x) => {
    window.scrollTo(0, 0);
    console.log(x, "dd");
    let questSize = question.length;
    let currentIndex = x;
    let c = (currentIndex / questSize) * 100;
    console.log(c, "dd");
    setNumber(x + 1);
    if (c > 75) {
      if (totalQ == question.length) {
        return;
      } else {
        getQuestions(filterObj, question);
      }
    }
  };

  const getQuestions = (
    paramObj = {
      ...filterObj,
      page: 1,
      test_type: test_type,
      page_size: 10,
      block_id: block_id,
    },
    list = []
  ) => {
    api_call_token
      .get(`test/aptitude_test_question/`, { params: { ...paramObj } })
      .then((res) => {
        setBlock(res.data.block_no);
        setPages(res.data.page);
        setTotalQuestion(res.data.total_question);
        setShowPassage(res.data.total_questions_main);
        setQuestion([...list, ...res.data.data]);
        if (res.data.page === 1 && !props?.location?.state?.fromType) {
          setTimers(res.data.total_time);
        }
        if (props?.location?.state?.fromType && res.data.page === 1) {
          api_call_token
            .get(`test/get_json/${res.data.block_no}/?test_type=${test_type}`)
            .then((res) => {
              setAnswer(res.data.data.json);
              setTimers(res.data.data.time_left);
            });
        }
        if (res.data.next_page) paramObj.page = res.data.next_page;
        else delete paramObj.page;
        setFilterObj(paramObj);
        setGoTo(1);
        console.log();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setTimers = (v) => {
    const val = v.split(":");
    let hrs = +val[0] * 60 * 60;
    let min = +val[1] * 60;
    let sec = +val[2];
    const total = hrs + +min + +sec;
    setTime(total);
    console.log(time, "setTimers ");
  };

  const setAns = (ansid, quid) => {
    let index = answer.findIndex((x) => x.question === quid);
    if (index === -1) {
      setAnswer([
        ...answer,
        {
          question: quid,
          answer_data: [{ answer_choice: ansid }],
          avg_time_spend: Math.floor(Math.random() * 11),
        },
      ]);
    } else {
      answer[index].question = quid;
      answer[index].answer_data[0].answer_choice = ansid;
      answer[index].avg_time_spend = Math.floor(Math.random() * 11);
      setAnswer([...answer]);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const SubmitTest = useCallback(() => {
    Calculate(hours, minutes, seconds);
  });

  const Calculate = (hrs, min, sec) => {
    api_call_token
      .patch(`test/get_json/${block}/?test_type=${test_type}`, {
        json: answer,
        time_left: `${hrs}:${min}:${sec}`,
      })
      .then((res) => {
        if (res.data.data?.id) {
          api_call_token
            .post(
              `test/aptitude_test_answer/save_answers/?test_type=${test_type}&block_id=${block_id}`,
              { data: answer }
            )
            .then((res) => {
              if (!props?.location?.state?.guardEx) {
                setGuardianExists(1);
                history.push(`/user-registration`);
              } else {
                history.push(`/result/${test_type}`);
              }
              // setGoTo(2);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleDrawer = (open) => (event) => {
    setState(open);
  };

  useEffect(() => {
    if (window.Tawk_API) {
      window.Tawk_API.hideWidget();
    }
    if (props?.location?.state?.fromSubmit) {
      setGoTo(2);
    } else {
      getQuestions();
      const pages = JSON.parse(localStorage.getItem("page"));
      if (pages) {
        setPages(pages);
      }

      const retrieveAnswer = JSON.parse(localStorage.getItem("answer"));
      if (retrieveAnswer) setAnswer(retrieveAnswer);
      window.addEventListener("beforeunload", getAnswer);
      window.addEventListener("onload", getAnswer);

      return () => {
        window.removeEventListener("beforeunload", getAnswer);
        window.removeEventListener("onload", getAnswer);
        // if (window.Tawk_API) {
        //   window.Tawk_API.showWidget();
        // }
      };
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem("page", page);
  }, [page]);

  useEffect(() => {
    console.log(localStorage.getItem("timer"));

    localStorage.getItem("timer") == 0
      ? SubmitTest()
      : console.log(localStorage?.getItem("timer"), "get timer failed");
  }, [time]);

  useEffect(() => {
    console.log(number);
    window.localStorage.setItem("number", number);
  }, [number]);

  useEffect(() => {
    if (answer?.length) {
      window.localStorage.setItem("answer", JSON.stringify(answer));
    }
  }, [answer]);

  // console.log(question.length, "questionLength", totalQ, "totalQ")
  // console.log(question[number - 1]?.questions?.length - 1, "redirection")
  return (
    <div className={styles.Back_position}>
      {!goToTest === 0 && (
        <div className={styles.mains_test}>
          <div style={{ width: "100%", height: "100vh" }}>
            {[1, 2, 3, 4, 5].map((v, i) => (
              <div style={{ marginBottom: "10px" }}>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="auto"
                  height={300}
                  key={i}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {goToTest === 1 && (
        <>
          <div className={styles.show_data}>
            <button
              onClick={toggleDrawer(true)}
              className={`${styles.btn_starts_test} ${styles.show_question}`}
            >
              Show Questions
            </button>
            <div className={styles.test_section}>
              {question[number]?.title ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      backgroundColor: "#c3c3c3",
                      borderRadius: "8px",
                      marginLeft: "10px",
                    }}
                  >
                    Q {question[number]?.question_no}
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      backgroundColor: "#c3c3c3",
                      borderRadius: "8px",
                    }}
                  >
                    Q {question[number]?.questions[nums]?.question_no}
                  </div>
                </div>
              )}
            </div>

            <div className={styles.clock_sub}>
              {/* <div align="center">Timer :{timer}</div> */}
              <div style={{ width: "20px", marginRight: "10px" }}>
                <img src={clock} style={{ width: "100%" }} />
              </div>
              {/* <div>Countdown: {counter}</div> */}
              <div
                className={styles.clock_width}
              >{`${hours}: ${minutes}: ${seconds}`}</div>
              {/* <StopWatch data={time} sub={sub} Calculate={Calculate} /> */}

              <div className={styles.submFinal_btn}>
                <button
                  className={`${styles.btn_next} ${styles.next_btns}`}
                  style={{ marginLeft: "10px" }}
                  onClick={() => QuitTest()}
                >
                  Quit
                </button>
                <button
                  onClick={handleOpen}
                  className={`${styles.btn_starts_test} ${styles.submit_btn}`}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>

          <div className={styles.mains_test}>
            {
              <>
                {
                  <>
                    {question[number]?.title ? (
                      <div className={styles.test_section}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: question[number]?.title,
                          }}
                          className={styles.quest}
                        ></div>
                        {question[number]?.title_image ? (
                          <img
                            src={question[number]?.title_image}
                            alt="image"
                            className={styles.images_test}
                          />
                        ) : (
                          <></>
                        )}

                        <div className={styles.answer_wrapper}>
                          {question[number]?.choices?.map((x, idx) => {
                            return x.title ? (
                              <>
                                <button
                                  onClick={() =>
                                    setAns(x.id, question[number].id)
                                  }
                                  className={`${styles.btn_answers} ${
                                    answer?.some(
                                      (e) =>
                                        e.question === question[number].id &&
                                        e.answer_data[0].answer_choice === x.id
                                    )
                                      ? styles.btn_succes
                                      : null
                                  }`}
                                >
                                  <span className={styles.chars}>
                                    {char[idx]}
                                  </span>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: x?.title,
                                    }}
                                    style={{
                                      display: "flex",
                                      textAlign: "left",
                                    }}
                                  ></div>
                                </button>
                              </>
                            ) : (
                              <></>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <Passage
                        quests={question[number]}
                        questlength={question.length}
                        number={number}
                        setNumber={setNumber}
                        setAnswer={setAnswer}
                        getNewQuestion={getNewQuestion}
                        setNums={setNums}
                        nums={nums}
                        answer={answer}
                        passageRedirect={passageRedirect}
                        getBack={getBack}
                        setGetBack={setGetBack}
                      />
                    )}
                  </>
                }

                {question[number]?.title ? (
                  <div className={styles.test_section}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "20px",
                      }}
                    >
                      {number === 0 ? (
                        <></>
                      ) : (
                        <>
                          {question[number]?.title ? (
                            <button
                              onClick={() => {
                                setNumber(number - 1);
                                setGetBack(true);
                                window.scrollTo(0, 0);
                              }}
                              className={styles.btn_next}
                            >
                              Back
                            </button>
                          ) : (
                            <>
                              {/* {<button
                              onClick={() => passageRedirect(question[number - 1]?.questions?.length - 1)}
                              className={styles.btn_next}
                            >
                              Back
                            </button>
                            } */}
                            </>
                          )}
                        </>
                      )}
                      {number === question.length - 1 ? (
                        <></>
                      ) : (
                        <button
                          onClick={() => {
                            getNewQuestion(number);
                            setGetBack(false);
                          }}
                          className={`${styles.btn_next} ${styles.next_btns}`}
                          style={{ marginRight: "0px" }}
                        >
                          Next
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <SwipeableDrawer
                  anchor={window.innerWidth < 767 ? "bottom" : "left"}
                  open={state}
                  onClose={toggleDrawer(false)}
                  onOpen={toggleDrawer(true)}
                  classes={{ paper: classes.BackdropProps }}
                >
                  <div className={styles.mob_drawer}>
                    <div className={styles.show_total_question}>
                      Total Number of Questions: {showPassage}
                    </div>
                    {question.map((val, idx) =>
                      val.title ? (
                        <div
                          className={`${styles.test_box} ${
                            answer?.some((e) => e.question === val.id)
                              ? styles.btn_green
                              : null
                          } ${number === idx ? styles.border : null}`}
                          onClick={() => {
                            setNumber(idx);
                            getNewQuestion(idx - 1);
                          }}
                        >
                          <div className={styles.whitespace}>
                            Q {val?.question_no}{" "}
                          </div>
                          <div
                            dangerouslySetInnerHTML={{ __html: val.title }}
                            className={styles.question_display}
                          ></div>
                        </div>
                      ) : (
                        val.questions.map((val, i) => (
                          <div
                            className={`${styles.test_box} ${
                              answer?.some((e) => e.question === val.id)
                                ? styles.btn_green
                                : null
                            }`}
                            onClick={() => {
                              setNumber(idx);
                              getNewQuestion(idx - 1);
                              setPassageRedirect(i);
                            }}
                          >
                            <div className={styles.whitespace}>
                              Q {val?.question_no}{" "}
                            </div>
                            <div
                              dangerouslySetInnerHTML={{ __html: val?.title }}
                              className={styles.question_display}
                            ></div>
                            {/* <div> Passage</div> */}
                          </div>
                        ))
                      )
                    )}

                    <button
                      className={`${
                        totalQ === question.length
                          ? styles.grey
                          : styles.getNextSet
                      } ${styles.widthHundred}`}
                      onClick={() => getQuestions(filterObj, question)}
                      disabled={totalQ === question.length ? true : false}
                    >
                      Get Next Set
                    </button>
                  </div>
                </SwipeableDrawer>

                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  <div style={modalStyle} className={classes.paper}>
                    <h2 id="simple-modal-title">Alert</h2>
                    <p
                      id="simple-modal-description"
                      style={{
                        fontSize: "18px",
                        color: "#4a3d3d",
                        lineHeight: 1.4,
                      }}
                    >
                      Once submitted the test, you won't able to take a retest.
                    </p>
                    <button
                      className={`${styles.btn_starts_test} ${styles.submit_btn}`}
                      onClick={() => SubmitTest()}
                      style={{ marginLeft: "0" }}
                    >
                      Submit
                    </button>
                  </div>
                </Modal>
              </>
            }
          </div>
        </>
      )}

      {goToTest === 2 && (
        <div className={styles.mains_test}>
          <ViewResult testType={test_type} />
        </div>
      )}
    </div>
  );
}

export default SingleChoice;
