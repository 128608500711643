import React, { Component, createContext } from "react";
import { api_call } from "../../Utils/Network";
export const UserCredsContext = createContext();
const localstorage_state = localStorage.getItem("user_creds_context");

function getLocalData(keyname) {
  let object = undefined;

  try {
    object = JSON.parse(localstorage_state);
  } catch {
    console.error("There was error while parsing data from localstorage.");
  }

  if (object) {
    if (object[keyname]) {
      return object[keyname];
    }
  }

  if (keyname === "authenticated") return false;
  if (keyname === "group") return [];
  if (keyname === "user") return {};
  if (keyname === "token")
    return {
      access: "",
      refresh: "",
    };
  if (keyname === "filter")
    return {
      type: "",
      value: null,
    };
  if (keyname === "userType") return null;
  if (keyname === "partnerType") return null;
  if (keyname === "openModel") return false;
  if (keyname === "displayType") return 1;
  if (keyname === "studentProfile") return {};
  if (keyname === "course") return {};
  if (keyname === "teacherProfile") return {};
  if (keyname === "displayType")
    return {
      redirect: true,
      purchase: false,
    };
  if (keyname === "otpLogin")
    return {
      code: "+91",
      phone: "9773526679",
      otp: "",
      type: "login",
    };
  if (keyname === "guardianExists") return 0;
  return "";
}

class UserCredsContextProvider extends Component {
  state = {
    otpLogin: getLocalData("otpLogin"),
    user_type: getLocalData("userType"),
    partner_type: getLocalData("partnerType"),
    studentProfile: getLocalData("studentProfile"),
    teacherProfile: getLocalData("teacherProfile"),
    displayType: getLocalData("displayType"),
    openModel: getLocalData("openModel"),
    filter: getLocalData("filter"),
    token: getLocalData("token"),
    user: getLocalData("user"),
    course: getLocalData("course"),
    redirectMyspace: getLocalData("redirectMyspace"),
    redirectMyAccount: getLocalData("redirectMyAccount"),
    guardianExists: getLocalData("guardianExists"),
    grades: [],
    boards: [],
    country: [],
    state: [],
  };

  componentDidMount() {
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("user_creds_context", JSON.stringify(this.state));
    });
    
    this.gradeList();
    this.boardList();
    this.countryList();
    this.stateList();
  }

  boardList = () => {
    api_call.get(`/base/board/`).then((res) => this.setState({ boards: res.data.data }))
  }

  gradeList = () => {
    api_call.get(`/base/grade/`).then((res) => this.setState({ grades: res.data.data }))
  }

  countryList = () => {
    api_call.get(`/profile/country/`).then((res) => this.setState({ country: res.data.data }))
  }

  stateList = () => {
    api_call.get(`/profile/state/`).then((res) => this.setState({ state: res.data.data }))
  }

  setOtpLogin(_data) {
    const update_data = { ...this.state.otpLogin, ..._data };
    this.setState({ otpLogin: update_data });
  }

  setToken(_token) {
    const { access, refresh } = _token;
    this.setState({ token: { access, refresh } });
  }

  setUserType(_usertype) {
    console.log(_usertype, "type in context");
    this.setState({ user_type: _usertype });
  }

  setPartnerType(_partnerType) {
    this.setState({ partner_type: _partnerType });
  }

  setStudentProfile(_profile) {
    this.setState({ studentProfile: _profile });
  }

  setTeacherProfile(_teachProfile) {
    this.setState({ teacherProfile: _teachProfile });
  }

  setFilter(_filter) {
    this.setState({ filter: _filter });
  }

  setDisplayType(_display) {
    this.setState({ displayType: _display });
  }

  setOpenModel(_model) {
    this.setState({ openModel: _model });
  }

  setRedirectMyspace(_redirectValue, _purchaseValue) {
    var redirect_data = { redirect: _redirectValue, purchase: _purchaseValue };
    this.setState({ redirectMyspace: redirect_data });
  }
  setRedirectMyAccount(_redirectValue, _purchaseValue) {
    var redirect_data = { redirect: _redirectValue, purchase: _purchaseValue };
    this.setState({ redirectMyAccount: redirect_data });
  }

  setUser(_user) {
    console.log(_user, "user ");
    this.setState({ user: _user });
  }
  setGuardian(_guardian) {
    this.setState({ guardianExists: _guardian });
  }
  setCourseDetail(_course) {
    console.log(_course, "_course1234 ");
    this.setState({ course: _course });
  }

  logout = () => {
    this.setState(
      {
        otpLogin: {
          id: "1",
          code: "+91",
          phone: "9773526679",
          otp: "",
          type: "login",
        },
        studentProfile: {},
        token: {
          access: "",
          refresh: "",
        },
        user: {},
        courseDetail: {},
        guardianExists: 0,
      },
      () => {
        localStorage.clear();

        setTimeout(() => {
          window.location.href = "/";
        }, 500);
      }
    );
  };
  render() {
    const { studentProfile, user, token } = this.state;
    return (
      <UserCredsContext.Provider
        value={{
          loginData: this.state.otpLogin,
          updateLoginData: this.setOtpLogin.bind(this),
          userType: this.state.user_type,
          updateUserType: this.setUserType.bind(this),
          partnerType: this.state.partner_type,
          updatePartnerType: this.setPartnerType.bind(this),
          token: this.state.token,
          setToken: this.setToken.bind(this),
          filter: this.state.filter,
          setFilter: this.setFilter.bind(this),
          displayType: this.state.displayType,
          setDisplayType: this.setDisplayType.bind(this),
          openModel: this.state.openModel,
          setOpenModel: this.setOpenModel.bind(this),
          studentProfile: this.state.studentProfile,
          setStudentProfile: this.setStudentProfile.bind(this),
          teacherProfile: this.state.teacherProfile,
          setTeacherProfile: this.setTeacherProfile.bind(this),
          user: this.state.user,
          setUser: this.setUser.bind(this),

          courseDetail: this.state.course,
          setCourseDetail: this.setCourseDetail.bind(this),

          redirectMyspace: this.state.redirectMyspace,
          setRedirectMyspace: this.setRedirectMyspace.bind(this),
          redirectMyAccount: this.state.redirectMyAccount,
          setRedirectMyAccount: this.setRedirectMyAccount.bind(this),
          logout: this.logout.bind(this),
          guardianExists: this.state.guardianExists,
          setGuardianExists: this.setGuardian.bind(this),
          boardList: this.state.boards,
          gradeList: this.state.grades,
          statelist : this.state.state,
          countrylist: this.state.country,
        }}
      >
        {this.props.children}
      </UserCredsContext.Provider>
    );
  }
}

export default UserCredsContextProvider;
