import React, { useState, useEffect } from "react";
import { api_call_token } from "../../Utils/Network";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import styles from "./TestSeries.module.css";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function ResultById(props) {
  let { id } = useParams();
  const [viewData, setViewData] = useState(6);
  const [breakDown, setBreakDown] = useState([]);
  const [datas, setDatas] = useState({});
  let history = useHistory();
  const [ofldatas, setOflDatas] = useState({});
  const [interset, setInterest] = useState([])
  let { test_type } = useParams();

  useEffect(() => {
    if (window.Tawk_API) {
      window.Tawk_API.hideWidget();
    }
    return () => {
      if (window.Tawk_API) {
        window.Tawk_API.showWidget();
      }
    };
  }, []);

  const ViewFinalResult = () => {
    api_call_token
      .get(`test/result_view/get/${id}`)
      // .get(`/test/result_view/overall/?${test_type}`)
      .then((res) => {
        setDatas(res.data.data);
        const { all_question_breakdown } = res.data.data;
        setBreakDown(all_question_breakdown);
        setViewData(6);
        window.localStorage.removeItem("answer");
        window.localStorage.removeItem("minutes");
        window.localStorage.removeItem("number");
      })
      .catch((err) => console.log(err));
  };

  const handleClick = () => {
    // history.push("/testseries");
    props.history.goBack();
  };

  const handleAskCounselor = () => {
    history.push("/ask-to-counselor");
    // props.history.goBack();
  };
  const onPDFViewClick = () => {
    datas.pdf ? window.open(datas.pdf) : alert("please try after some time");
  };
  const oflViewClick = () => {
    ofldatas.pdf
      ? window.open(ofldatas.pdf)
      : alert("Please try after some time");
  };
  console.log(ofldatas.pdf, "oflViewClick");

  const ViewOflFinalResult = () => {
    api_call_token
      .get(`test/result_view/offline_overall/?test_type=interest`)
      .then((res) => {
        console.log(res, "oflViewClick");

        setOflDatas(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const viewInterest = () => {
    api_call_token
      .get(`/test/result_view/overall/?${test_type}`)
      .then((res) => {
        if (res.data.data.interest_test_category_and_percentage) {

          setInterest(res.data.data.interest_test_category_and_percentage)
        }
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    ViewFinalResult();
    ViewOflFinalResult();
    viewInterest()
  }, []);

  console.log(test_type, "DATADA")
  return (
    <div className={styles.Back_position}>
      {/* {viewData === 5 && (
        <div className={styles.viewResults}>
          <button onClick={() => ViewFinalResult()} className={styles.btn_starts_test}>View Result</button>
        </div>
      )} */}
      {viewData === 6 && (
        <div className={styles.finalResults}>
          <div className={styles.resuts}>Result </div>
          {/* <div className={styles.detailsfinal}>Name: {datas.full_name}</div> */}

          <div className={styles.viewResult_box}>
            <div style={{ paddingTop: "45px" }}>
              <div className={styles.detailsfinalTitle}>
                Hi {datas.full_name},<br /> Your parameters score result is
                here.
              </div>
              {/* <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>No. of Attempts: { datas?.no_of_attempts}</div> */}
              <p className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Total Questions: {datas?.total_questions} */}
                {` Total Questions: ${datas?.total_questions || 0}`}
              </p>

              {/* <p className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {datas.correct_answers ?
                  `Correct Answers: ${datas.correct_answers}` :""}
              </p> */}
              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Correct Answers: {datas?.correct_answers} */}
                {!datas.correct_answers >= 0 &&
                  ` Correct Answers: ${datas.correct_answers}`}
              </div>

              <p className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Wrong Answers: {datas?.wrong_answers || datas?.no_of_attempts} */}
                {` Wrong Answers: ${datas?.wrong_answers || 0}`}
              </p>

              <p className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Unattempted Questions: {datas?.unattempted_questions} */}
                {` Unattempted Questions:  ${datas?.unattempted_questions || 0}`}
              </p>

              <p className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Average Time Spent per Question: {datas?.avg_time} sec */}
                {datas.avg_time &&
                  `Average Time Spent Per Question:  ${datas?.avg_time || 0} sec`}
              </p>

              <p className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Total Time Spent: {datas?.total_time} */}
                {datas.total_time && `Total Time Spent: ${datas.total_time}`}
              </p>
            </div>

            {
              (interset.length > 0) ? <div>
                {interset &&
                  interset.map((data, idx) => (
                    <div key={idx} className={styles.res_break}>
                      {data.category === "" ? (
                        <></>
                      ) : (
                        <div className={styles.res_break_sub}>
                          <p>{data?.category} </p>
                        </div>
                      )}
                      <div className={styles.result_bar}>
                        {data.value === null ? (
                          <></>
                        ) : (
                          <div
                            style={{
                              width: `${data?.value}%`,
                              backgroundColor: `${data?.colour}`,
                              height: "13px",
                              borderBottomLeftRadius: "12px",
                              borderTopLeftRadius: "12px",
                            }}
                          ></div>
                        )}
                        <div className={styles.show_percent}>
                          {category_percentage_breakdown.map((val, idx) => (
                            <div
                              style={{
                                width: `${val.percentage}%`,
                                height: "13px",
                                borderRight: "4px solid white",
                              }}
                            ></div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
              </div> :
                <div>
                  {breakDown &&
                    breakDown.map((data, idx) => (
                      <div key={idx} className={styles.res_break}>
                        {data.category_percent_range === null ? (
                          <></>
                        ) : (
                          <div className={styles.res_break_sub}>
                            <p>{data?.breakdown} </p>
                            {/* <p>
                          {data?.category_percent_range?.title}{" "}
                          {data?.percent + "%"}
                        </p> */}
                          </div>
                        )}
                        <div className={styles.result_bar}>
                          {data.category_percent_range === null ? (
                            <></>
                          ) : (
                            <div
                              style={{
                                width: `${data?.percent}%`,
                                backgroundColor: `${data?.category_percent_range?.color}`,
                                height: "13px",
                                borderBottomLeftRadius: "12px",
                                borderTopLeftRadius: "12px",
                              }}
                            ></div>
                          )}
                          <div className={styles.show_percent}>
                            {data?.category_percentage_breakdown.map((val, idx) => (
                              <div
                                style={{
                                  width: `${val.percentage}%`,
                                  height: "13px",
                                  borderRight: "4px solid white",
                                }}
                              ></div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
            }

          </div>
          <button className={styles.getNextSet} onClick={() => handleClick()}>
            Get Back to Test
          </button>
          {/* <button
            className={styles.getNextSet}
            onClick={() => onPDFViewClick()}
            disabled={datas.pdf ? false : true}
            style={
              datas.pdf
                ? { cursor: "pointer" }
                : { cursor: "not-allowed", backgroundColor: "#92929eb8" }
            }
          >
            Parameter Score
          </button> */}
          {/* <button
            className={styles.getNextSet}
            onClick={() => handleAskCounselor()}
          >
            Talk to our Counsellor
          </button> */}
        </div>
      )}
    </div>
  );
}

export default ResultById;


const category_percentage_breakdown = [
{percentage: 30, order: 1, title: "Novice", color: "#FF6FB5"},
{percentage: 30, order: 2, title: "Intermediate", color: "#FF6FB5"},
{percentage: 20, order: 3, title: "Advance", color: "#FF6FB5"},
{percentage: 10, order: 4, title: "Scholar", color: "#FF6FB5"},
{percentage: 10, order: 5, title: "Ace", color: "#FF6FB5"}
]