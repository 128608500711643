import React, { Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "../Pages/Home/home.js";
import About from "../Pages/About/About.js";
import Terms from "../Pages/Terms/Terms";
import Test from "../Pages/Test/Test.js";
import TeachOnLcarro from "../Pages/TeachOnLcarro/TeachOnLcarro.js";
import SchoolRegistration from "../Components/SchoolRegistration/SchoolRegistration.js";
import StudentRegistration from "../Components/StudentRegistration/StudentRegistration.js";
import OnlinePartnersRegistration from "../Components/OnlinePartnersRegistration/OnlinePartnersRegistration.js";
import CoachingInstituteRegistration from "../Components/CoachingInstituteRegistration/CoachingInstituteRegistration.js";
import TeachersRegistration from "../Components/TeachersRegistration/TeachersRegistration";
import CourseDetail from "../Pages/CourseDetail/CourseDetail";

// import Search from "../Pages/Search/Search.js";
import Navbar from "../Components/NavBar/Navbar.js";

import ExplorePage from "../Pages/Explore/index.js";
import Footer from "../Components/Footer/Footer.js";
import PurchasePage from "../Pages/PurchasePage/PurchasePage.js";
import MySpacePage from "../Pages/MySpace/index.js";
import MyAccount from "../Pages/MyAccount/MyAccount.js";

import RefundCancelPolicy from "../Pages/RefundandCancel/RefundCancelPolicy.js";
import Privacy from "../Pages/Privacy/Privacy.js";
import Compare from "../Pages/Compare/index.js";
import Contact from "../Pages/Contact/Contact.js";
import TakeTest from "../Pages/TakeTest/TakeTest.js";
import TestSeries from "../Pages/TestSeries/TestSeries.js";
import ViewResult from "../Pages/TestSeries/ViewResult.js";
import ViewAllResult from "../Pages/TestSeries/ViewAllResult";
import SingleChoice from "../Pages/TestSeries/SingleChoice.js";
import HomeRewamp from "../Pages/HomeRewamp/HomeRewamp.js";
import BrainHap from "../Pages/BrainHap/BrainHap.js";
import OverAllResult from "../Pages/TestSeries/OverAllResult.js";
import ResultById from "../Pages/TestSeries/ResultById.js";
import AskToCounselor from "../Pages/TestSeries/AskToCounselor.js";
import Blog from "../Pages/Blog/Blog.js";
import RedirectCookie from "../Pages/Redirect/RedirectCookie.js";
import FormDetail from "../Components/DialogBox/FormDetail.js";
// import Cards from "../Pages/Cards/Cards.js";
import Feeds from "../Pages/Rating/Feeds";
import NewMyAccount from "../Pages/MyAccount/NewMyAccount.js";

function AppRouter() {
  return (
    <Fragment>
      <Navbar />
      {/* <Route component={Search}/> */}
      <Switch>
        {/* <Route exact path="/" component={Home} /> */}
        {/* <Route exact path="/brain-hap" component={BrainHap} /> */}
        {/* <Route exact path="/" component={HomeRewamp} /> */}
        <Route exact path="/" component={BrainHap} />
        <Route exact path="/about-us" component={About} />
        <Route exact path="/test" component={Test} />
        <Route exact path="/testseries" component={TestSeries} />
        <Route
          exact
          path="/testseries/:test_type/:block_id"
          component={SingleChoice}
        />
        <Route exact path="/result/:test_type" component={ViewResult} />
        <Route
          exact
          path="/result_view/overall/:test_type"
          component={OverAllResult}
        />
        <Route
          exact
          path="/get-all-result/all/:test_type"
          component={ViewAllResult}
        />
        <Route exact path="/result_view/get/:id/:test_type" component={ResultById} />
        {/* <Route exact path="/result_view/get/:test_type" component={ResultById} /> */}
        <Route exact path="/teachonlcarro" component={TeachOnLcarro} />
        <Route exact path="/ask-to-counselor" component={AskToCounselor} />
        <Route
          exact
          path="/schoolregistration"
          component={SchoolRegistration}
        />
        <Route
          exact
          path="/studentregistration"
          component={StudentRegistration}
        />
        <Route
          exact
          path="/onlinepartnersregistration"
          component={OnlinePartnersRegistration}
        />
        <Route
          exact
          path="/coachinginstituteregistration"
          component={CoachingInstituteRegistration}
        />
        <Route
          exact
          path="/teachersregistration"
          component={TeachersRegistration}
        />
        <Route exact path="/termsandcondition" component={Terms} />
        <Route exact path="/refundandcancel" component={RefundCancelPolicy} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/taketest" component={TakeTest} />
        <Route exact path="/blog" S component={Blog} />
        <Route
          exact
          path="/coursedetail/:course_id/:entity_type/"
          component={CourseDetail}
        />
        <Route
          exact
          path="/purchase/:course_id/:validity_id/"
          component={PurchasePage}
        />
        <Route exact path="/my-space" component={MySpacePage} />
        {/* <Route exact path="/my-account" component={MyAccount} /> */}
        <Route exact path="/my-account" component={NewMyAccount} />


        {/* <Route exact path="/example/course/:course_id/:entity_type/" component={CourseDetailPage}/> */}

        {/* <Route exact path="/card" component={Cards} /> */}
        <Route exact path="/explore" component={ExplorePage} />
        <Route exact path="/compare" component={Compare} />
        <Route path="/redirect-setcookie/:token" component={RedirectCookie} />
        <Route exact path="/user-registration" component={FormDetail} />
        <Route exact path="/feedbacks" component={Feeds} />
      </Switch>
      <Footer />
    </Fragment>
  );
}

export default AppRouter;

// export const ProtectedRoutes = ({ component: CFomponent, ...rest }) => {
//   const { token } = useContext(UserCredsContext);
//   return (
//     <Fragment>
//       <Route
//         {...rest}
//         render={(props) => {
//           return token.access ? <CFomponent {...props} /> : <Redirect to="/" />;
//         }}
//       />
//     </Fragment>
//   );
// };
