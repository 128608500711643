import React, { useState, useEffect, useCallback } from "react";
import { api_call_token } from "../../Utils/Network";
import styles from "./TestSeries.module.css";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function ViewResult(props) {
  const [viewData, setViewData] = useState(6);
  const [breakDown, setBreakDown] = useState([]);
  const [oflbreakDown, setOflBreakDown] = useState([]);
  const [datas, setDatas] = useState({});
  let history = useHistory();
  const [ofldatas, setOflDatas] = useState({});
  const [loading, setLoading] = useState(true);

  const [minimumTime, setMinimumTime] = useState(500);
  const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);
  const [interset, setInterest] = useState([])

  let { test_type } = useParams();
  useEffect(() => {
    if (window.Tawk_API) {
      window.Tawk_API.hideWidget();
    }
    return () => {
      if (window.Tawk_API) {
        window.Tawk_API.showWidget();
      }
      restartTimeout();
    };
  }, []);
  const restartTimeout = useCallback(() => {
    setMinimumTimeElapsed(false);
    setLoading(true);
    //simulate random load time between 0 and 5 seconds
    const randomLoadTime = Math.random() * 5000;
    setTimeout(() => {
      setMinimumTimeElapsed(true);
    }, minimumTime);
    setTimeout(() => {
      setLoading(false);
    }, randomLoadTime);
  }, [setMinimumTimeElapsed, setLoading, minimumTime]);

  const ViewFinalResult = () => {
    setLoading(true);
    api_call_token
      .get(`test/result_view/view_stats/?test_type=${test_type}`)
      .then((res) => {
        const { all_question_breakdown } = res.data.data;
        setDatas(res.data.data);
        setBreakDown(all_question_breakdown);
        // setViewData(6);
        window.localStorage.removeItem("answer");
        window.localStorage.removeItem("minutes");
        window.localStorage.removeItem("number");
        setLoading(false);
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const handleClick = () => {
    history.push("/testseries");
    // props.history.goBack();
  };

  const handleAskCounselor = () => {
    history.push("/ask-to-counselor");
    // props.history.goBack();
  };

  const onPDFViewClick = () => {
    datas.pdf ? window.open(datas.pdf) : alert("please try after some time");
  };

  console.log(datas, "datas");

  // const OfflineResult = () => {
  //   setLoading(true);
  //   api_call_token
  //     .get(`test/result_view/offline_overall/?test_type=${test_type}`)
  //     .then((res) => {
  //       console.log(res, "oflViewClick");
  //       // setOflDatas(res.data.data);
  //       // setOflBreakDown(all_question_breakdown);
  //       setLoading(false);
  //     })
  //     .catch((err) => console.log(err));
  // };

  useEffect(() => {
    ViewFinalResult();
    // OfflineResult();
    setLoading(false);
    viewDataInterest();
  }, []);


  
  const viewDataInterest = () => {
    api_call_token
      .get(`/test/result_view/overall/?${test_type}`)
      .then((res) => {
        if (res.data.data.interest_test_category_and_percentage) {

          setInterest(res.data.data.interest_test_category_and_percentage)
        }
      })
      .catch(err => console.log(err))
  }

  console.log(datas, "datas");
  console.log(breakDown, "breakDown");
  console.log("ofl datas", ofldatas);
  console.log("ofl breakDown", oflbreakDown);
  return (
    <div className={styles.Back_position}>
      {/* {viewData === 6 && ( */}
      <div className={styles.finalResults}>
        <div className={styles.resuts}>Result </div>
        {(datas) ? (
          <div className={styles.viewResult_box}>
            <div style={{ paddingTop: "45px" }}>
              <div className={styles.detailsfinal}>Name: {datas.full_name}</div>

              {/* <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>No. of Attempts: { datas?.no_of_attempts}</div> */}
              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Total Questions: {datas?.total_questions} */}
                {datas.total_questions &&
                  ` Total Questions:  ${datas.total_questions}`}
              </div>

              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {datas.correct_answers &&
                  `Correct Answers: ${datas.correct_answers}`}
              </div>

              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Wrong Answers: {datas?.wrong_answers || datas?.no_of_attempts} */}
                {datas.wrong_answers &&
                  ` Wrong Answers: ${datas.wrong_answers}`}
              </div>

              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                
                {
                  `Unattempted Questions:  ${datas?.unattempted_questions || 0}`}
                
              </div>

              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Average Time Spent per Question: {datas?.avg_time} sec */}
                {datas.avg_time &&
                  `Average Time Spent Per Question:  ${datas?.avg_time || 0} sec`}
              </div>

              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Total Time Spent: {datas?.total_time} */}
                {datas.total_time && `Total Time Spent: ${datas.total_time}`}
              </div>
            </div>

            {/* <div>
              {breakDown &&
                breakDown.map((data, idx) => (
                  <div className={styles.res_break}>
                    {data.category_percent_range === null ? (
                      <></>
                    ) : (
                      <div className={styles.res_break_sub}>
                        <p>{data?.breakdown} </p>
                      </div>
                    )}
                    <div className={styles.result_bar}>
                      {data.category_percent_range === null ? (
                        <></>
                      ) : (
                        <div
                          style={{
                            width: `${data?.percent}%`,
                            backgroundColor: `${data?.category_percent_range?.color}`,
                            height: "13px",
                            borderBottomLeftRadius: "12px",
                            borderTopLeftRadius: "12px",
                          }}
                        ></div>
                      )}
                      <div className={styles.show_percent}>
                        {data?.category_percentage_breakdown.map((val, idx) => (
                          <div
                            style={{
                              width: `${val.percentage}%`,
                              height: "13px",
                              borderRight: "4px solid white",
                            }}
                          ></div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
            </div> */}


            {/*New Interest test*/}
            {
              (interset.length > 0) ? <div>
                {interset &&
                  interset.map((data, idx) => (
                    <div key={idx} className={styles.res_break}>
                      {data.category === "" ? (
                        <></>
                      ) : (
                        <div className={styles.res_break_sub}>
                          <p>{data?.category} </p>
                        </div>
                      )}
                      <div className={styles.result_bar}>
                        {data.value === null ? (
                          <></>
                        ) : (
                          <div
                            style={{
                              width: `${data?.value}%`,
                              backgroundColor: `${data?.colour}`,
                              height: "13px",
                              borderBottomLeftRadius: "12px",
                              borderTopLeftRadius: "12px",
                            }}
                          ></div>
                        )}
                        <div className={styles.show_percent}>
                          {category_percentage_breakdown.map((val, idx) => (
                            <div
                              style={{
                                width: `${val.percentage}%`,
                                height: "13px",
                                borderRight: "4px solid white",
                              }}
                            ></div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
              </div> :
                <div>
                  {breakDown &&
                    breakDown.map((data, idx) => (
                      <div key={idx} className={styles.res_break}>
                        {data.category_percent_range === null ? (
                          <></>
                        ) : (
                          <div className={styles.res_break_sub}>
                            <p>{data?.breakdown} </p>
                            {/* <p>
                          {data?.category_percent_range?.title}{" "}
                          {data?.percent + "%"}
                        </p> */}
                          </div>
                        )}
                        <div className={styles.result_bar}>
                          {data.category_percent_range === null ? (
                            <></>
                          ) : (
                            <div
                              style={{
                                width: `${data?.percent}%`,
                                backgroundColor: `${data?.category_percent_range?.color}`,
                                height: "13px",
                                borderBottomLeftRadius: "12px",
                                borderTopLeftRadius: "12px",
                              }}
                            ></div>
                          )}
                          <div className={styles.show_percent}>
                            {data?.category_percentage_breakdown.map((val, idx) => (
                              <div
                                style={{
                                  width: `${val.percentage}%`,
                                  height: "13px",
                                  borderRight: "4px solid white",
                                }}
                              ></div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
            }
          </div>
        ) : null}
        {ofldatas?.pdf ? (
          <div className={styles.viewResult_box}>
            <div style={{ paddingTop: "45px" }}>
              <div className={styles.detailsfinal}>
                Name: {ofldatas.full_name}
              </div>

              {/* <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>No. of Attempts: { ofldatas?.no_of_attempts}</div> */}
              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Total Questions: {ofldatas?.total_questions} */}
                {ofldatas.total_questions &&
                  ` Total Questions:  ${ofldatas.total_questions}`}
              </div>

              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {
                  `Correct Answers: ${ofldatas.correct_answers || 0}`}
              </div>

              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Wrong Answers: {ofldatas?.wrong_answers || ofldatas?.no_of_attempts} */}
                {
                  ` Wrong Answers: ${ofldatas.wrong_answers || 0}`}
              </div>

              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Unattempted Questions: {ofldatas?.unattempted_questions} */}
                {ofldatas.unattempted_questions &&
                  `Unattempted Questions:  ${ofldatas.unattempted_questions}`}
              </div>

              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Average Time Spent per Question: {ofldatas?.avg_time} sec */}
                {ofldatas.avg_time &&
                  `Average Time Spent Per Question:  ${ofldatas?.avg_time || 0} sec`}
              </div>

              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Total Time Spent: {ofldatas?.total_time} */}
                {ofldatas.total_time &&
                  `Total Time Spent: ${ofldatas.total_time}`}
              </div>
            </div>

            <div>
              {oflbreakDown &&
                oflbreakDown.map((data, idx) => (
                  <div className={styles.res_break}>
                    {data.category_percent_range === null ? (
                      <></>
                    ) : (
                      <div className={styles.res_break_sub}>
                        <p>{data?.oflbreakDown} </p>
                        {/* <p>
                          {data?.category_percent_range?.title} {"  "}
                          {data?.percent + "%"}
                        </p> */}
                      </div>
                    )}
                    <div className={styles.result_bar}>
                      {data.category_percent_range === null ? (
                        <></>
                      ) : (
                        <div
                          style={{
                            width: `${data?.percent}%`,
                            backgroundColor: `${data?.category_percent_range?.color}`,
                            height: "13px",
                            borderBottomLeftRadius: "12px",
                            borderTopLeftRadius: "12px",
                          }}
                        ></div>
                      )}
                      <div className={styles.show_percent}>
                        {data?.category_percentage_breakdown.map((val, idx) => (
                          <div
                            style={{
                              width: `${val.percentage}%`,
                              height: "13px",
                              borderRight: "4px solid white",
                            }}
                          ></div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : null}
        <button className={styles.getNextSet} onClick={() => handleClick()}>
          Get Back to Test
        </button>
        <button
          className={styles.getNextSet}
          onClick={() => onPDFViewClick()}
          disabled={datas.pdf ? false : true}
          style={
            datas.pdf
              ? { cursor: "pointer" }
              : { cursor: "not-allowed", backgroundColor: "#92929eb8" }
          }
        >
          Parameter Score
        </button>
        {/* <button
          className={styles.getNextSet}
          onClick={() => handleAskCounselor()}
        >
          Consult Expert
        </button> */}
      </div>
      {/* )} */}
    </div>
  );
}

export default ViewResult;

const category_percentage_breakdown = [
  {percentage: 30, order: 1, title: "Novice", color: "#FF6FB5"},
  {percentage: 30, order: 2, title: "Intermediate", color: "#FF6FB5"},
  {percentage: 20, order: 3, title: "Advance", color: "#FF6FB5"},
  {percentage: 10, order: 4, title: "Scholar", color: "#FF6FB5"},
  {percentage: 10, order: 5, title: "Ace", color: "#FF6FB5"}
  ]
