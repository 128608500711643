import React, { useEffect, useState, useContext, useRef } from "react";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import TextField from "@material-ui/core/TextField";
import { api_call_token, login_api } from "../../Utils/Network";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import StudBook from "../../Assets/1x/registartion_page.png";
import { CloseOutlined, Email } from "@material-ui/icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Input,
  Paper,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import {
  alpha,
  withStyles,
  makeStyles,
  createTheme,
} from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Success from "./Success";
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { event } from "react-ga";
import { Alert } from "@material-ui/lab";

export const EmailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  textWidth: {
    width: "86%",
    marginBottom: "10px !important",
    display: "flex",
    flexDirection: "column",
    margin: "auto"
  },
  textWidth2: {
    width: "50%",
    margin: "10px !important",
  },
  mainText: {
    width: "90%",
  },
  textWidth2List: {
    fontSize: " 0.875rem",
    fontWeight: "600",
    lineHeight: "1.75",
    whiteSpace: "normal",
    letterSpacing: " 0.02857em",
  },
}));

function FormDetail(props) {
  // console.log(props, "props");
  const {
    user,
    loginData,
    setStudentProfile,
    setToken,
    updateLoginData,
    setUser,
    guardianExists,
    setGuardianExists,
  } = useContext(UserCredsContext);
  const [_data, setData] = useState([]);
  const [_board, setBoard] = useState(null);
  const [exam, setExam] = useState([]);
  const [getexam, setgExam] = useState(null);
  const [type, setType] = useState(1);
  const [errors, setErrors] = useState({});
  // const [enable, setEnable] = useState(true);
  const [stateId, setStateId] = useState([]);
  const [genderValue, setGenderValue] = useState(null);
  const [filterGender, setFilterGender] = useState(0);
  const [filterGrade, setFilterGrade] = useState(0);
  const [genders, setGenders] = useState([
    { id: 1, title: "MALE" },
    { id: 2, title: "FEMALE" },
    // { id: 3, title: "TRANSGENDER" },
    // { id: 4, title: "OTHER" },
  ]);

  const [rel, setRel] = useState(null);
  const [relation, setRelation] = useState([
    { id: 1, title: "Father" },
    { id: 2, title: "Mother" },
    { id: 3, title: "Brother" },
    { id: 4, title: "Sister" },
    { id: 5, title: "Uncle" },
    { id: 6, title: "Aunt" },
    { id: 7, title: "Niece" },
    { id: 8, title: "Nephew" },
    { id: 9, title: "Cousin" },
    { id: 10, title: "Grandfather" },
    { id: 11, title: "Grandmother" },
    { id: 12, title: "Other" },
  ]);
  const [gradeUpd, setGrade] = useState(null);
  const [submitsucess, setSubmitSuccess] = useState(0);
  const [dob, setDob] = useState(null);
  const [showUpdate, setShowUpdate] = useState(0);
  const [guardian_data, setGuardian_Data] = useState([]);

  const [guardian_Datas_new, setGuardian_Datas_new] = useState([]);
  const [guardian_Datas_old, setGuardian_Datas_old] = useState([
    {
      first_name: "",
      last_name: "",
      G_name: "",
      email: "",
      occupation: "",
      institute: "",
      education: "",
      phone: "",
      relationship: null,
    },
  ]);

  const [userData, setUserData] = useState({
    // first_name: "",
    // last_name: "",
    name: "",
    grade: {},
    board: {},
    pass: "",
    renter: "",
    email: "",
    inst: "",
    city: "",
    // Gfirst_name: "",
    // Glast_name: "",
    // G_name: "",
    // gemail: "",
    // occupation: "",
    // ginst: "",
    // geducation: "",
    // gphone: "",
    // guardian_data,
  });

  const [value, setValue] = React.useState(0);
  const [theArray, setTheArray] = useState([]);
  const [openAlert, setOpenAlert] = React.useState(true);
  let history = useHistory();

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setShowUpdate(newValue);
  };

  let temp = {};
  const handleChange = (event, val) => {
    const op = val !== null ? setBoard(val.id) : "Please select Board";
    setErrors({ ...errors, op });
  };

  const handleChanged = (event, values) => {
    const outpt = values !== null ? setGrade(values.id) : "Please select Grade";
    setFilterGrade(stateId.findIndex((el) => el.id === values?.id));
    setErrors({ ...errors, outpt });
  };

  const handleRelation = (values, event) => {
    console.log(event, values, "hh");

    let guardian = [...guardian_Datas_old];
    guardian[event].relationship = values?.id;
    setGuardian_Datas_old([...guardian]);
    const relat =
      values !== null ? setRel(values.id) : "Please select Relation";
    setErrors({ ...errors, relat });
  };

  const handleGender = (event, values) => {
    const vals =
      values !== null ? setGenderValue(values?.id) : "Please select Gender";
    setFilterGender(genders.findIndex((el) => el.id === +values?.id));
    setErrors({ ...errors, vals });
  };
  const handleChanges = (event, value) => {
    // console.log(event.target.value, "value");

    const output =
      event.target.value !== null
        ? setDob(event.target.value)
        : "Please Select the DOB";

    setErrors({ ...errors, output });
  };

  const getExam = () => {
    login_api
      .get(`/base/exam/`)
      .then((response) => {
        setExam(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBoard = () => {
    login_api
      .get(`/base/board/`)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getGrade = () => {
    login_api
      .get(`/base/grade/`)
      .then((response) => {
        // console.log(response.data.data, "board/grade");
        setStateId(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getGurdianData = (async) => {
    login_api.get(`auth/v2/register/${user.id}/`).then((response) => {
      console.log(response.data.data.guardian, "getGurdianData");
      setGuardian_Datas_old(response.data.data.guardian);
    });
  };

  useEffect(() => {
    getBoard();
    getGrade();
    getExam();
    getGurdianData();
  }, []);

  useEffect(() => {
    if (props?.location?.state?.update) {
      setGuardianExists(1);
    }
    getGurdianData();
  }, [props?.location?.state?.update]);

  //Data If already added
  useEffect(() => {
    if (user?.id) {
      setUserData({
        first_name: user?.first_name || "",
        last_name: user?.last_name || "",
        name: `${user?.first_name ? user.first_name : ""} ${user?.last_name ? user.last_name : ""
          }`,
        grade: user?.profile?.grade?.id || "",
        G_name: "",
        gphone: "",
      });
    }

    if (user?.profile?.grade) {
      setGrade(user?.profile?.grade?.id);
    }
    setFilterGender(genders.findIndex((el) => el.id === +user.gender));
    if (stateId.length !== 0) {
      // console.log(stateId.findIndex((el) => el.id === user?.profile?.grade?.id),"data");
      setFilterGrade(
        stateId.findIndex((el) => el.id === user?.profile?.grade?.id)
      );
    }
    if (user?.gender) {
      setGenderValue(user.gender);
    } else {
      setGenderValue(null);
    }
    // getGurdianData();
  }, [user, stateId]);

  useEffect(() => {
    if (guardianExists === 0) {
      setShowUpdate(1);
    }
  }, [guardianExists]);

  const handleChangeData = (event) => {
    const { name, value } = event.target;

    setUserData({ ...userData, [name]: value });
    // if(name === "first_name"){
    //   var check = userData.first_name
    // }
    if (name == "renter") {
      var checkpass = userData.pass === value ? "" : "Password does not match";
      setErrors({ ...errors, checkpass });
    } else {
      setErrors({});
    }
  };

  const handleChangeGuardian = (event, idx) => {
    const { name, value } = event.target;
    console.log(name, value, "ddds");
    let guardian = [...guardian_Datas_old];

    if (name === "G_name") {
      let vals = value.split(" ");
      guardian[idx].first_name = vals[0];
      guardian[idx].last_name = vals[1];
    } else {
      guardian[idx][name] = value;
    }

    console.log(guardian, "guards");
    setGuardian_Datas_old([...guardian]);
    // if(name === "first_name"){
    //   var check = userData.first_name
    // }
  };
  // const handleChangeDataGuardian = (event) => {
  //   console.log(event.target.value);
  //   const { name, value } = event.target;

  //   setGuardian_Data({ ...setGuardian_Data, [name]: value });
  //   // if(name === "first_name"){
  //   //   var check = userData.first_name
  //   // }
  //   if (name === "renter") {
  //     var checkpass = setGuardian_Data.pass === value ? "" : "Password does not match";
  //     setErrors({ ...errors, checkpass });
  //   } else {
  //     setErrors({});
  //   }
  // };
  // console.log(errors, "Re enter password error");
  const validatePersoaldetail = () => {
    // console.log(userData, " userData validatePersoaldetail");

    let returnValue = true;
    let nameTest = /^[a-zA-Z ]{2,30}$/;

    temp.name = nameTest.test(userData.name) ? "" : "Please enter valid name.";

    temp.pass =
      userData.pass === ""
        ? "Please enter password"
        : userData?.pass?.length < 8
          ? "Please enter password greater than 8"
          : "";
    temp.renter =
      userData.renter === ""
        ? "Please enter re-password"
        : !userData.renter == userData.pass
          ? "Please enter re-password greater than 8"
          : "";
    // // temp.name = nameTest.test(userData.name) ? "" : "Please enter valid name.";
    temp.grade = gradeUpd !== null ? "" : "Please Select Grade";

    setErrors({
      ...temp,
    });
    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("required") || temp[key].includes("Please");
    });

    if (found.length) {
      returnValue = false;
    }
    return returnValue;
  };

  const validate = () => {
    if (
      !guardian_Datas_old[0]?.first_name &&
      !guardian_Datas_old[0]?.phone &&
      !guardian_Datas_old[0]?.relationship
    ) {
      alert("Please Add Guardian Details.");
    }

    let returnValue = true;
    let nameTest = /^[a-zA-Z ]{2,30}$/;
    let emailTest = "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$";
    temp.name = nameTest.test(userData.name) ? "" : "Please enter valid name.";

    temp.G_name = nameTest.test(guardian_Datas_old[0]?.first_name)
      ? ""
      : "Please enter valid Guardian name.";

    temp.gphone =
      /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(
        guardian_Datas_old[0]?.phone
      )
        ? guardian_Datas_old[0]?.phone.length > 10 ||
          guardian_Datas_old[0]?.phone.length < 10
          ? "Please Enter valid phone number"
          : ""
        : "Please Enter valid phone number";
    setErrors({
      ...temp,
    });

    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("required") || temp[key].includes("Please");
    });

    if (found.length) {
      returnValue = false;
    }
    return returnValue;
  };
  console.log(errors, "error");
  // Alert(errors);
  // <Alert severity="error">{errors?.["value"]}</Alert>;
  const SubmitData = () => {
    if (guardianExists === 0) {
      if (!validatePersoaldetail()) {
        return null;
      }

      let phoneNumber = loginData.phone;
      if (phoneNumber.length > 10) {
        phoneNumber = phoneNumber.substring(2); // remove first two characters
      }
      let nams = userData.name.trim();
      let va = nams.split(" ");
      let datas = {
        profile: {
          // studies_in: [study],
          // board_id: _board,
          grade_id: gradeUpd,
          title: "Mr",
          dob: dob,
        },
        user_type: 1,
        password: userData.pass,
        name: `${va[0]} ${va[1]}`,
        first_name: va[0],
        last_name: va[1],
        phone: phoneNumber,
        gender: genderValue,
      };

      console.log(datas, nams, va, "vffvvv");

       // .post(`auth/register_v2/`, datas)
      login_api
        .post(`auth/v2/lcarro/${loginData.id}/student_register/`, datas)      
        .then((response) => {
          // console.log(response, "RESPONSE")
          let { token, user } = response.data.data;
          setToken(token);
          localStorage.setItem("network_access_token", token.access);
          setUser(user);
          setSubmitSuccess(1);
        })
        .catch((err) => {
          alert("Error Filling Data or User might already exist")
        });
      // let new_data = {
      //   profile: {
      //     // studies_in: [study],
      //     // board_id: _board,
      //     grade_id: gradeUpd,
      //     title: "Mr",
      //     dob: dob,
      //   },
      //   user_type: 1,
      //   password: userData.pass,
      //   name: userData.name,
      //   first_name: userData?.first_name,
      //   last_name: userData?.last_name,
      //   institute_name: userData.inst,
      //   city: userData.city,
      //   email: userData.email,
      //   gender: genderValue,
      //   guardian: guardian_Datas_new,
      // };
      // console.log(new_data, " new_data");
      // api_call_token
      //   .patch(`auth/v2/register/${user.id}/`, new_data)
      //   .then((res) => {
      //     // console.log(res, "Update Profile");
      //     // setGuardian_Data(res.data.data.guardian);
      //     // setGuardian_Datas_new([...guardian_data, datas.guardian]);
      //     console.log(guardian_Datas_new, "  Guardian_Datas_new");
      //     console.log(res.data, " res.data newwwwwwww");
      //     // history.push("/testseries");
      //   })
      //   .catch((err) => console.log(err));
    }

    if (guardianExists === 1) {
      if (!validate()) {
        return null;
      }
      let nams = userData.name.trim();
      let fname = nams.split(" ");
      // let Gnams = userData.G_name.trim();
      // let Gfname = Gnams.split(" ");

      let datas = {
        profile: {
          // studies_in: [study],
          // board_id: _board,
          grade_id: gradeUpd,
          title: "Mr",
          dob: dob,
        },
        user_type: 1,
        password: userData.pass,
        name: `${fname[0]} ${fname[1]}`,
        first_name: fname[0],
        last_name: fname[1],
        institute_name: userData.inst,
        city: userData.city,
        email: userData.email,
        gender: genderValue,
        guardian: [...guardian_Datas_old],
      };

      console.log(datas, "Updated Profile");

      api_call_token
        .patch(`auth/v2/register/${user.id}/`, datas)
        .then((res) => {
          // <Alert variant="outlined" severity="success">
          //   This is a success alert — check it out!
          // </Alert>;
          history.push("/testseries");
        })
        .catch((err) => console.log(err));
    }

    // const study = type === 1 ? 1 : type === 2 ? 2 : 3;
    // if (type === 1) {
    //   var datas = {
    //     profile: {
    //       // studies_in: [study],
    //       // board_id: _board,
    //       grade_id: gradeUpd,
    //       title: "Mr",
    //       dob: dob,
    //     },
    //     user_type: 1,
    //     password: userData.pass,
    //     name: userData.name,
    //     first_name: userData.first_name,
    //     last_name: userData.last_name,
    //     institute_name: userData.inst,
    //     city: userData.city,
    //     email: userData.email,
    //     gender: genderValue,
    //     guardian: [
    //       {
    //         relationship: rel,
    //         first_name: userData.Gfirst_name,
    //         last_name: userData.Glast_name,
    //         phone: userData.gphone,
    //         email: userData.gemail,
    //         occupation: userData.occupation,
    //         education: userData.geducation,
    //       },
    //     ],
    //   };
    // } else if (type === 3 || type === 2) {
    //   var datas = {
    //     profile: {
    //       studies_in: [study],
    //       exam: [getexam],
    //       title: "Mr",
    //     },
    //     user_type: 1,
    //     password: userData.pass,
    //     first_name: userData.first_name,
    //     last_name: userData.last_name,
    //     phone: loginData.phone,
    //   };
    // }

    // console.log(gradeUpd, "gradeUpd");

    // login_api
    //   .post(`auth/v2/lcarro/${loginData.id}/student_register/`, datas)
    //   .then((response) => {
    //     console.log(response.data.data, "ProfileLogin");
    //     let { token, user } = response.data.data;
    //     setToken(token);
    //     localStorage.setItem("network_access_token", token.access);
    //     // console.log(token.access, " token");
    //     // console.log(
    //     //   localStorage.getItem("network_access_token", token.access),
    //     //   " token"
    //     // );

    //     setUser(user);
    //     setSubmitSuccess(1);
    //     // updateLoginData({
    //     //   type: "subm",
    //     // });
    //     // console.log(datas, datas, "datas");

    //   });

    // login_api
    //   .post(`auth/lcarro/${loginData.id}/student_register/`, datas)
    //   .then((response) => {
    //     console.log(response.data.data, "ProfileLogin");
    //     let { token, user } = response.data.data;
    //     setToken(token);
    //     localStorage.setItem("network_access_token", token.access);
    //     // console.log(token.access, " token");
    //     // console.log(
    //     //   localStorage.getItem("network_access_token", token.access),
    //     //   " token"
    //     // );

    //     setUser(user);
    //     updateLoginData({
    //       type: "subm",
    //     });
    //   });
  };

  // console.log(guardianExists, "guardianExists");

  const classes = useStyles();

  const [expanded, setExpanded] = React.useState("");

  const addEntryClick = () => {
    setGuardian_Datas_old([
      ...guardian_Datas_old,
      {
        first_name: "",
        last_name: "",
        G_name: "",
        email: "",
        occupation: "",
        institute: "",
        education: "",
        phone: "",
      },
    ]);
  };
  const deleteItem = (index) => () => {
    setGuardian_Datas_old((items) => items.filter((_, i) => i !== index));
  };

  return (
    <>
      {submitsucess == 0 && (
        <div className={`form_data ${classes.ValidationTextField}`} style={{ width: '100%' }}>
          <ThemeProvider>
            <div
              className="form_image_container"
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                width: '50%',
                // background:
                //   "linear-gradient(38.67deg, #ffd36e -64.26%, rgba(255, 255, 255, 0) 101.28%)",
              }}
            >
              <img
                src={StudBook}
                alt="Book"
                className="bk_book form_data_box_img"
              ></img>
            </div>

            <div
              className="form_data_box2"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <Paper className={classes.root} centered>
                <Tabs
                  value={value}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                  style={{ justifyContent: "center" }}
                  TabIndicatorProps={{
                    style: {
                      height: "6px",
                      background: "#2F327D",
                      borderRadius: " 10px 10px 0px 0px",
                    },
                  }}
                >
                  <Tab
                    label="Personal Details"
                    {...a11yProps(0)}
                    style={{ justifyContent: "center" }}
                  />
                  {guardianExists !== 0 ? (
                    <Tab label="Parents Details" {...a11yProps(1)} />
                  ) : (
                    <></>
                  )}
                </Tabs>
              </Paper>

              <TabPanel value={value} index={0} className={classes.mainText}>
                <div>
                  <TextField
                    value={userData.name}
                    label="Full Name"
                    onChange={handleChangeData}
                    placeholder="Full Name"
                    className={classes.textWidth}
                    variant="outlined"
                    id="outlined-full-width"
                    name="name"
                    required
                  // style={{ width: "27em" }}
                  ></TextField>
                  <p className="error-message-text error-marginbottom">
                    {errors.name}
                  </p>
                </div>

                {guardianExists !== 0 ? (
                  <>
                    <div>
                      <TextField
                        name="inst"
                        label="School Name"
                        value={userData.inst}
                        onChange={handleChangeData}
                        placeholder="School Name"
                        className={classes.textWidth}
                        // id="mui-theme-provider-outlined-TextField"
                        type="text"
                        id="outlined-full-width"
                        // style={{ margin: 8 }}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                      />
                      <p className="error-message-text error-marginbottom">
                        {errors.inst}
                      </p>
                    </div>
                    <div>
                      <TextField
                        name="email"
                        className={classes.textWidth}
                        value={userData.email}
                        onChange={handleChangeData}
                        placeholder="Email address"
                        label="Email address"
                        variant="outlined"
                        id="outlined-full-width"
                        inputProps={{
                          pattern:
                            "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$",
                        }}
                      ></TextField>
                      <p className="error-message-text error-marginbottom">
                        {errors.email}
                      </p>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {guardianExists !== 0 ? (
                  <div>
                    <TextField
                      className={classes.textWidth}
                      name="city"
                      value={userData.city}
                      onChange={handleChangeData}
                      placeholder="City"
                      label="City"
                      variant="outlined"
                      id="outlined-full-width"
                    ></TextField>
                    <p className="error-message-text error-marginbottom">
                      {errors.city}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                <div>
                  {stateId.length !== 0 && (
                    <Autocomplete
                      value={stateId[filterGrade]}
                      id="combo-box-demos"
                      options={stateId}
                      style={{ margin: "10px auto" }}
                      onChange={handleChanged}
                      className={classes.textWidth}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => (
                        <TextField
                          required
                          variant="outlined"
                          {...params}
                          label="Grade"
                          name="grade"
                        />
                      )}
                    />
                  )}
                  <p className="error-message-text error-marginbottom">
                    {errors.grade}
                  </p>
                </div>
                <div>
                  <Autocomplete
                    value={genders[filterGender]}
                    id="combo-box-demo"
                    options={genders}
                    style={{ margin: "10px auto" }}
                    onChange={handleGender}
                    autoComplete="off"
                    className={classes.textWidth}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        autoComplete="off"
                        {...params}
                        label="Gender"
                        name="gender"
                      />
                    )}
                  />
                  <p className="error-message-text error-marginbottom">
                    {errors.genders}
                  </p>
                </div>
                {guardianExists !== 0 ? (
                  <div>
                    <TextField
                      className={classes.textWidth}
                      label="Date of Birth"
                      type="date"
                      onChange={handleChanges}
                      defaultValue="2022-07-01"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      variant="outlined"
                      id="outlined-full-width"
                      name="birthday"
                    />
                  </div>
                ) : (
                  <></>
                )}

                {guardianExists === 0 && (
                  <div className="">
                    <div className="form_input_box">
                      <TextField
                        name="pass"
                        value={userData.pass}
                        onChange={handleChangeData}
                        label="Password"
                        placeholder="Password"
                        className={classes.textWidth}
                        variant="outlined"
                        id="outlined-full-width"
                        type="password"
                        required
                      ></TextField>
                      <p className="error-message-text error-marginbottom">
                        {errors.pass}
                      </p>
                    </div>
                    <div className="form_input_box">
                      <TextField
                        name="renter"
                        onChange={handleChangeData}
                        value={userData.renter}
                        placeholder="Re-Enter Password"
                        label="Re-Enter Password"
                        className={classes.textWidth}
                        variant="outlined"
                        id="outlined-full-width"
                        type="password"
                        required
                      ></TextField>
                      <p className="error-message-text error-marginbottom">
                        {errors.checkpass}
                      </p>
                    </div>
                  </div>
                )}
              </TabPanel>

              <TabPanel value={value} index={1} className={classes.mainText}>
                {guardian_Datas_old &&
                  guardian_Datas_old.map((val, i) => (
                    <div className="Bord_main">
                      <div className="">
                        <TextField
                          name="G_name"
                          value={val[i]?.G_name}
                          // onChange={handleChangeData}
                          onChange={(e) => handleChangeGuardian(e, i)}
                          placeholder="Parents Full Name"
                          label="Parents Full Name"
                          className={classes.textWidth}
                          variant="outlined"
                          id="outlined-full-width"
                          required
                        ></TextField>
                        <p className="error-message-text error-marginbottom">
                          {errors.G_name}
                        </p>
                      </div>
                      <div className="">
                        <TextField
                          name="phone"
                          value={val[i]?.phone}
                          // onChange={handleChangeData}
                          onChange={(e) => handleChangeGuardian(e, i)}
                          placeholder="Phone No."
                          label="Phone No."
                          className={classes.textWidth}
                          variant="outlined"
                          id="outlined-full-width"
                          type="number"
                          required
                        ></TextField>
                        <p className="error-message-text error-marginbottom">
                          {errors.gphone}
                        </p>
                        {/* email */}
                        <TextField
                          name="email"
                          value={val[i]?.email}
                          // onChange={handleChangeData}
                          onChange={(e) => handleChangeGuardian(e, i)}
                          placeholder="Email address"
                          label="Email address"
                          className={classes.textWidth}
                          variant="outlined"
                          id="outlined-full-width"
                        ></TextField>
                        <p className="error-message-text error-marginbottom">
                          {errors.gemail}
                        </p>
                      </div>
                      <div className="">
                        <TextField
                          name="occupation"
                          value={val[i]?.occupation}
                          // onChange={handleChangeData}
                          onChange={(e) => handleChangeGuardian(e, i)}
                          placeholder="Occupation"
                          label="Occupation"
                          className={classes.textWidth}
                          variant="outlined"
                          id="outlined-full-width"
                          type="text"
                        ></TextField>
                        <p className="error-message-text error-marginbottom">
                          {errors.occupation}
                        </p>

                        <TextField
                          name="education"
                          value={val[i]?.education}
                          // onChange={handleChangeData}
                          onChange={(e) => handleChangeGuardian(e, i)}
                          placeholder="Education"
                          label="Education"
                          className={classes.textWidth}
                          variant="outlined"
                          id="outlined-full-width"
                        ></TextField>
                        <p className="error-message-text error-marginbottom">
                          {errors.geducation}
                        </p>
                      </div>
                      <div>
                        <Autocomplete
                          id="combo-box-demo"
                          options={relation}
                          style={{ margin: "0 auto" }}
                          onChange={(e, value) => handleRelation(value, i)}
                          className={classes.textWidth}
                          getOptionLabel={(option) => option.title}
                          renderInput={(params) => (
                            <TextField
                              required
                              variant="outlined"
                              {...params}
                              label="Relation"
                              name="relation"
                            />
                          )}
                        />
                        <p className="error-message-text error-marginbottom">
                          {errors.relat}
                        </p>
                      </div>
                      {
                        <button
                          className="btn_nv_tech"
                          onClick={deleteItem(value)}
                        >
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setOpenAlert(false);
                            }}
                          >
                            Remove
                            <CloseOutlined fontSize="inherit" />
                          </IconButton>
                        </button>
                      }
                    </div>
                  ))}
              </TabPanel>
              <div>
                <Grid
                  direction="row"
                  container
                  // xs={8}
                  justifyContent="space-around"
                  alignItems="center"
                  item
                >
                  {value === 0 && guardianExists === 1 && (
                    <Button
                      variant="contained"
                      className="btn_model"
                      onClick={() => handleChangeTab("e", 1)}
                      color="primary"
                      style={{
                        borderRadius: "12px",
                        width: "auto",
                        fontSize: "18px",
                        fontWeight: "550",
                        marginBottom: "10px",
                      }}
                    // disabled={enable}
                    >
                      Next
                    </Button>
                  )}

                  {value === 1 && (
                    <Button
                      variant="contained"
                      className="btn_model"
                      onClick={addEntryClick}
                      color="primary"
                      style={{
                        borderRadius: "12px",
                        fontSize: "18px",
                        fontWeight: "550",
                        marginBottom: "10px",
                      }}
                    // disabled={enable}
                    >
                      Add Guardian
                    </Button>
                  )}
                  {showUpdate === 1 && (
                    <Button
                      variant="contained"
                      className="btn_model"
                      onClick={SubmitData}
                      color="primary"
                      style={{
                        borderRadius: "12px",
                        width: "auto",
                        fontSize: "18px",
                        fontWeight: "600",
                        padding: "10px",
                      }}
                    // disabled={enable}
                    >
                      {guardianExists !== 0 ? "Update Profile" : "Submit"}
                    </Button>
                  )}
                </Grid>
              </div>
            </div>
          </ThemeProvider>
        </div>
      )}
      {submitsucess === 1 && <Success />}
    </>
  );
}
export default FormDetail;

const demo = [
  {
    gender: "Male",
  },
  {
    gender: "Female",
  },
];
